import 'react/jsx-runtime';
import 'react';

/**
 * Variable checking the server environment runtime.
 */ const IS_SERVER_ENVIRONMENT = typeof window === "undefined";
/**
 * Variable to detect the test environment.
 * Testing runtimes (Jest and Vitest) automatically set the `NODE_ENV` environment variable to `test`.
 * Thanks to the `DefinePlugin` setup, the bundler minifier automatically strips the conditional code statement if NODE_ENV !== "test".
 * @see {@link https://vitest.dev/guide/migration.html#envs Node environment injection}.
 */ process.env.NODE_ENV === "test";
/**
 * Create a shell-like id.
 * @param component Optional component name owned by the shell.
 * @returns The shell-like id.
 */ const createShellLikeId = (component)=>{
    return `shell_ufrn${"" }`;
};
/**
 * Follows the registry `CreateId` convention (app replaced with shell).
 * @see {@link https://github.com/axel-springer-kugawana/aviv_platform_unified_frontend_registry/blob/e6d26f7ec237210f8f928fd6c866f24a80b8a678/libraries/registry/types/index.ts#L12C13-L12C29 Id format}.
 */ const SHELL_ID = createShellLikeId();

/* eslint-disable local-rules/disallow-side-effects */
/**
 * Keep references on console methods to avoid triggering patched behaviors
 *
 * NB: in some setup, console could already be patched by another SDK.
 * In this case, some display messages can be sent by the other SDK
 * but we should be safe from infinite loop nonetheless.
 */
var ConsoleApiName = {
    log: 'log',
    debug: 'debug',
    info: 'info',
    warn: 'warn',
    error: 'error',
};
/**
 * When building JS bundles, some users might use a plugin[1] or configuration[2] to remove
 * "console.*" references. This causes some issue as we expect `console.*` to be defined.
 * As a workaround, let's use a variable alias, so those expressions won't be taken into account by
 * simple static analysis.
 *
 * [1]: https://babeljs.io/docs/babel-plugin-transform-remove-console/
 * [2]: https://github.com/terser/terser#compress-options (look for drop_console)
 */
var globalConsole = console;
var originalConsoleMethods = {};
Object.keys(ConsoleApiName).forEach(function (name) {
    originalConsoleMethods[name] = globalConsole[name];
});
var PREFIX = 'Datadog Browser SDK:';
var display = {
    debug: originalConsoleMethods.debug.bind(globalConsole, PREFIX),
    log: originalConsoleMethods.log.bind(globalConsole, PREFIX),
    info: originalConsoleMethods.info.bind(globalConsole, PREFIX),
    warn: originalConsoleMethods.warn.bind(globalConsole, PREFIX),
    error: originalConsoleMethods.error.bind(globalConsole, PREFIX),
};

function catchUserErrors(fn, errorMsg) {
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        try {
            return fn.apply(void 0, args);
        }
        catch (err) {
            display.error(errorMsg, err);
        }
    };
}

/**
 * LIMITATION:
 * For NPM setup, this feature flag singleton is shared between RUM and Logs product.
 * This means that an experimental flag set on the RUM product will be set on the Logs product.
 * So keep in mind that in certain configurations, your experimental feature flag may affect other products.
 *
 * FORMAT:
 * All feature flags should be snake_cased
 */
// We want to use a real enum (i.e. not a const enum) here, to be able to check whether an arbitrary
// string is an expected feature flag
// eslint-disable-next-line no-restricted-syntax
var ExperimentalFeature;
(function (ExperimentalFeature) {
    ExperimentalFeature["WRITABLE_RESOURCE_GRAPHQL"] = "writable_resource_graphql";
    ExperimentalFeature["CUSTOM_VITALS"] = "custom_vitals";
    ExperimentalFeature["TOLERANT_RESOURCE_TIMINGS"] = "tolerant_resource_timings";
})(ExperimentalFeature || (ExperimentalFeature = {}));
var enabledExperimentalFeatures = new Set();
function addExperimentalFeatures(enabledFeatures) {
    enabledFeatures.forEach(function (flag) {
        enabledExperimentalFeatures.add(flag);
    });
}
function getExperimentalFeatures() {
    return enabledExperimentalFeatures;
}

/**
 * Return true if the draw is successful
 * @param threshold between 0 and 100
 */
function performDraw(threshold) {
    return threshold !== 0 && Math.random() * 100 <= threshold;
}
function isPercentage(value) {
    return isNumber(value) && value >= 0 && value <= 100;
}
function isNumber(value) {
    return typeof value === 'number';
}

var ONE_SECOND = 1000;
var ONE_MINUTE = 60 * ONE_SECOND;
var ONE_HOUR = 60 * ONE_MINUTE;
function dateNow() {
    // Do not use `Date.now` because sometimes websites are wrongly "polyfilling" it. For example, we
    // had some users using a very old version of `datejs`, which patched `Date.now` to return a Date
    // instance instead of a timestamp[1]. Those users are unlikely to fix this, so let's handle this
    // case ourselves.
    // [1]: https://github.com/datejs/Datejs/blob/97f5c7c58c5bc5accdab8aa7602b6ac56462d778/src/core-debug.js#L14-L16
    return new Date().getTime();
}
function timeStampNow() {
    return dateNow();
}
function relativeNow() {
    return performance.now();
}
function clocksNow() {
    return { relative: relativeNow(), timeStamp: timeStampNow() };
}
function clocksOrigin() {
    return { relative: 0, timeStamp: getNavigationStart() };
}
function elapsed(start, end) {
    return (end - start);
}
function addDuration(a, b) {
    return a + b;
}
/**
 * Get the time since the navigation was started.
 *
 * Note: this does not use `performance.timeOrigin` because it doesn't seem to reflect the actual
 * time on which the navigation has started: it may be much farther in the past, at least in Firefox 71.
 * Related issue in Firefox: https://bugzilla.mozilla.org/show_bug.cgi?id=1429926
 */
function getRelativeTime(timestamp) {
    return (timestamp - getNavigationStart());
}
/**
 * Navigation start slightly change on some rare cases
 */
var navigationStart;
function getNavigationStart() {
    if (navigationStart === undefined) {
        navigationStart = performance.timing.navigationStart;
    }
    return navigationStart;
}

var ONE_KIBI_BYTE = 1024;
var ONE_MEBI_BYTE = 1024 * ONE_KIBI_BYTE;
// eslint-disable-next-line no-control-regex
var HAS_MULTI_BYTES_CHARACTERS = /[^\u0000-\u007F]/;
function computeBytesCount(candidate) {
    // Accurate bytes count computations can degrade performances when there is a lot of events to process
    if (!HAS_MULTI_BYTES_CHARACTERS.test(candidate)) {
        return candidate.length;
    }
    if (window.TextEncoder !== undefined) {
        return new TextEncoder().encode(candidate).length;
    }
    return new Blob([candidate]).size;
}

function includes(candidate, search) {
    return candidate.indexOf(search) !== -1;
}
function arrayFrom(arrayLike) {
    if (Array.from) {
        return Array.from(arrayLike);
    }
    var array = [];
    if (arrayLike instanceof Set) {
        arrayLike.forEach(function (item) { return array.push(item); });
    }
    else {
        for (var i = 0; i < arrayLike.length; i++) {
            array.push(arrayLike[i]);
        }
    }
    return array;
}
function find(array, predicate) {
    for (var i = 0; i < array.length; i += 1) {
        var item = array[i];
        if (predicate(item, i)) {
            return item;
        }
    }
    return undefined;
}
function objectValues(object) {
    return Object.keys(object).map(function (key) { return object[key]; });
}
function objectEntries(object) {
    return Object.keys(object).map(function (key) { return [key, object[key]]; });
}
function startsWith(candidate, search) {
    return candidate.slice(0, search.length) === search;
}
function endsWith(candidate, search) {
    return candidate.slice(-search.length) === search;
}
function assign(target) {
    var toAssign = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        toAssign[_i - 1] = arguments[_i];
    }
    toAssign.forEach(function (source) {
        for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
            }
        }
    });
    return target;
}

function shallowClone(object) {
    return assign({}, object);
}
function objectHasValue(object, value) {
    return Object.keys(object).some(function (key) { return object[key] === value; });
}
function isEmptyObject(object) {
    return Object.keys(object).length === 0;
}

/**
 * inspired by https://mathiasbynens.be/notes/globalthis
 */
function getGlobalObject() {
    if (typeof globalThis === 'object') {
        return globalThis;
    }
    Object.defineProperty(Object.prototype, '_dd_temp_', {
        get: function () {
            return this;
        },
        configurable: true,
    });
    // @ts-ignore _dd_temp is defined using defineProperty
    var globalObject = _dd_temp_;
    // @ts-ignore _dd_temp is defined using defineProperty
    delete Object.prototype._dd_temp_;
    if (typeof globalObject !== 'object') {
        // on safari _dd_temp_ is available on window but not globally
        // fallback on other browser globals check
        if (typeof self === 'object') {
            globalObject = self;
        }
        else if (typeof window === 'object') {
            globalObject = window;
        }
        else {
            globalObject = {};
        }
    }
    return globalObject;
}

/**
 * Gets the original value for a DOM API that was potentially patched by Zone.js.
 *
 * Zone.js[1] is a library that patches a bunch of JS and DOM APIs. It usually stores the original
 * value of the patched functions/constructors/methods in a hidden property prefixed by
 * __zone_symbol__.
 *
 * In multiple occasions, we observed that Zone.js is the culprit of important issues leading to
 * browser resource exhaustion (memory leak, high CPU usage). This method is used as a workaround to
 * use the original DOM API instead of the one patched by Zone.js.
 *
 * [1]: https://github.com/angular/angular/tree/main/packages/zone.js
 */
function getZoneJsOriginalValue(target, name) {
    var browserWindow = getGlobalObject();
    var original;
    if (browserWindow.Zone && typeof browserWindow.Zone.__symbol__ === 'function') {
        original = target[browserWindow.Zone.__symbol__(name)];
    }
    if (!original) {
        original = target[name];
    }
    return original;
}

var __spreadArray = (globalThis && globalThis.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var onMonitorErrorCollected;
var debugMode = false;
function startMonitorErrorCollection(newOnMonitorErrorCollected) {
    onMonitorErrorCollected = newOnMonitorErrorCollected;
}
function setDebugMode(newDebugMode) {
    debugMode = newDebugMode;
}
function monitored(_, __, descriptor) {
    var originalMethod = descriptor.value;
    descriptor.value = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var decorated = onMonitorErrorCollected ? monitor(originalMethod) : originalMethod;
        return decorated.apply(this, args);
    };
}
function monitor(fn) {
    return function () {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return callMonitored(fn, this, arguments);
    }; // consider output type has input type
}
function callMonitored(fn, context, args) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return fn.apply(context, args);
    }
    catch (e) {
        displayIfDebugEnabled(e);
        if (onMonitorErrorCollected) {
            try {
                onMonitorErrorCollected(e);
            }
            catch (e) {
                displayIfDebugEnabled(e);
            }
        }
    }
}
function displayIfDebugEnabled() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (debugMode) {
        display.error.apply(display, __spreadArray(['[MONITOR]'], args, false));
    }
}

function setTimeout(callback, delay) {
    return getZoneJsOriginalValue(getGlobalObject(), 'setTimeout')(monitor(callback), delay);
}
function clearTimeout(timeoutId) {
    getZoneJsOriginalValue(getGlobalObject(), 'clearTimeout')(timeoutId);
}
function setInterval(callback, delay) {
    return getZoneJsOriginalValue(getGlobalObject(), 'setInterval')(monitor(callback), delay);
}
function clearInterval(timeoutId) {
    getZoneJsOriginalValue(getGlobalObject(), 'clearInterval')(timeoutId);
}

var Observable = /** @class */ (function () {
    function Observable(onFirstSubscribe) {
        this.onFirstSubscribe = onFirstSubscribe;
        this.observers = [];
    }
    Observable.prototype.subscribe = function (f) {
        var _this = this;
        if (!this.observers.length && this.onFirstSubscribe) {
            this.onLastUnsubscribe = this.onFirstSubscribe(this) || undefined;
        }
        this.observers.push(f);
        return {
            unsubscribe: function () {
                _this.observers = _this.observers.filter(function (other) { return f !== other; });
                if (!_this.observers.length && _this.onLastUnsubscribe) {
                    _this.onLastUnsubscribe();
                }
            },
        };
    };
    Observable.prototype.notify = function (data) {
        this.observers.forEach(function (observer) { return observer(data); });
    };
    return Observable;
}());
function mergeObservables() {
    var observables = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        observables[_i] = arguments[_i];
    }
    return new Observable(function (globalObservable) {
        var subscriptions = observables.map(function (observable) {
            return observable.subscribe(function (data) { return globalObservable.notify(data); });
        });
        return function () { return subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); }); };
    });
}

// use lodash API
function throttle(fn, wait, options) {
    var inWaitPeriod = false;
    var pendingExecutionWithParameters;
    var pendingTimeoutId;
    return {
        throttled: function () {
            var parameters = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                parameters[_i] = arguments[_i];
            }
            if (inWaitPeriod) {
                pendingExecutionWithParameters = parameters;
                return;
            }
            {
                fn.apply(void 0, parameters);
            }
            inWaitPeriod = true;
            pendingTimeoutId = setTimeout(function () {
                if (pendingExecutionWithParameters) {
                    fn.apply(void 0, pendingExecutionWithParameters);
                }
                inWaitPeriod = false;
                pendingExecutionWithParameters = undefined;
            }, wait);
        },
        cancel: function () {
            clearTimeout(pendingTimeoutId);
            inWaitPeriod = false;
            pendingExecutionWithParameters = undefined;
        },
    };
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() { }

/**
 * UUID v4
 * from https://gist.github.com/jed/982883
 */
function generateUUID(placeholder) {
    return placeholder
        ? // eslint-disable-next-line  no-bitwise
            (parseInt(placeholder, 10) ^ ((Math.random() * 16) >> (parseInt(placeholder, 10) / 4))).toString(16)
        : "".concat(1e7, "-").concat(1e3, "-").concat(4e3, "-").concat(8e3, "-").concat(1e11).replace(/[018]/g, generateUUID);
}
var COMMA_SEPARATED_KEY_VALUE = /([\w-]+)\s*=\s*([^;]+)/g;
function findCommaSeparatedValue(rawString, name) {
    COMMA_SEPARATED_KEY_VALUE.lastIndex = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        var match = COMMA_SEPARATED_KEY_VALUE.exec(rawString);
        if (match) {
            if (match[1] === name) {
                return match[2];
            }
        }
        else {
            break;
        }
    }
}
function findCommaSeparatedValues(rawString) {
    var result = new Map();
    COMMA_SEPARATED_KEY_VALUE.lastIndex = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        var match = COMMA_SEPARATED_KEY_VALUE.exec(rawString);
        if (match) {
            result.set(match[1], match[2]);
        }
        else {
            break;
        }
    }
    return result;
}
function safeTruncate(candidate, length, suffix) {
    var lastChar = candidate.charCodeAt(length - 1);
    var isLastCharSurrogatePair = lastChar >= 0xd800 && lastChar <= 0xdbff;
    var correctedLength = isLastCharSurrogatePair ? length + 1 : length;
    if (candidate.length <= correctedLength) {
        return candidate;
    }
    return "".concat(candidate.slice(0, correctedLength)).concat(suffix);
}

function isChromium() {
    return detectBrowserCached() === 1 /* Browser.CHROMIUM */;
}
var browserCache;
function detectBrowserCached() {
    return browserCache !== null && browserCache !== void 0 ? browserCache : (browserCache = detectBrowser());
}
// Exported only for tests
function detectBrowser(browserWindow) {
    var _a;
    if (browserWindow === void 0) { browserWindow = window; }
    var userAgent = browserWindow.navigator.userAgent;
    if (browserWindow.chrome || /HeadlessChrome/.test(userAgent)) {
        return 1 /* Browser.CHROMIUM */;
    }
    if (
    // navigator.vendor is deprecated, but it is the most resilient way we found to detect
    // "Apple maintained browsers" (AKA Safari). If one day it gets removed, we still have the
    // useragent test as a semi-working fallback.
    ((_a = browserWindow.navigator.vendor) === null || _a === void 0 ? void 0 : _a.indexOf('Apple')) === 0 ||
        (/safari/i.test(userAgent) && !/chrome|android/i.test(userAgent))) {
        return 2 /* Browser.SAFARI */;
    }
    if (browserWindow.document.documentMode) {
        return 0 /* Browser.IE */;
    }
    return 3 /* Browser.OTHER */;
}

function setCookie(name, value, expireDelay, options) {
    var date = new Date();
    date.setTime(date.getTime() + expireDelay);
    var expires = "expires=".concat(date.toUTCString());
    var sameSite = options && options.crossSite ? 'none' : 'strict';
    var domain = options && options.domain ? ";domain=".concat(options.domain) : '';
    var secure = options && options.secure ? ';secure' : '';
    var partitioned = options && options.partitioned ? ';partitioned' : '';
    document.cookie = "".concat(name, "=").concat(value, ";").concat(expires, ";path=/;samesite=").concat(sameSite).concat(domain).concat(secure).concat(partitioned);
}
function getCookie(name) {
    return findCommaSeparatedValue(document.cookie, name);
}
var initCookieParsed;
/**
 * Returns a cached value of the cookie. Use this during SDK initialization (and whenever possible)
 * to avoid accessing document.cookie multiple times.
 */
function getInitCookie(name) {
    if (!initCookieParsed) {
        initCookieParsed = findCommaSeparatedValues(document.cookie);
    }
    return initCookieParsed.get(name);
}
function deleteCookie(name, options) {
    setCookie(name, '', 0, options);
}
function areCookiesAuthorized(options) {
    if (document.cookie === undefined || document.cookie === null) {
        return false;
    }
    try {
        // Use a unique cookie name to avoid issues when the SDK is initialized multiple times during
        // the test cookie lifetime
        var testCookieName = "dd_cookie_test_".concat(generateUUID());
        var testCookieValue = 'test';
        setCookie(testCookieName, testCookieValue, ONE_MINUTE, options);
        var isCookieCorrectlySet = getCookie(testCookieName) === testCookieValue;
        deleteCookie(testCookieName, options);
        return isCookieCorrectlySet;
    }
    catch (error) {
        display.error(error);
        return false;
    }
}
/**
 * No API to retrieve it, number of levels for subdomain and suffix are unknown
 * strategy: find the minimal domain on which cookies are allowed to be set
 * https://web.dev/same-site-same-origin/#site
 */
var getCurrentSiteCache;
function getCurrentSite() {
    if (getCurrentSiteCache === undefined) {
        // Use a unique cookie name to avoid issues when the SDK is initialized multiple times during
        // the test cookie lifetime
        var testCookieName = "dd_site_test_".concat(generateUUID());
        var testCookieValue = 'test';
        var domainLevels = window.location.hostname.split('.');
        var candidateDomain = domainLevels.pop();
        while (domainLevels.length && !getCookie(testCookieName)) {
            candidateDomain = "".concat(domainLevels.pop(), ".").concat(candidateDomain);
            setCookie(testCookieName, testCookieValue, ONE_SECOND, { domain: candidateDomain });
        }
        deleteCookie(testCookieName, { domain: candidateDomain });
        getCurrentSiteCache = candidateDomain;
    }
    return getCurrentSiteCache;
}

var SESSION_STORE_KEY = '_dd_s';

var SESSION_TIME_OUT_DELAY = 4 * ONE_HOUR;
var SESSION_EXPIRATION_DELAY = 15 * ONE_MINUTE;

var SESSION_ENTRY_REGEXP = /^([a-zA-Z]+)=([a-z0-9-]+)$/;
var SESSION_ENTRY_SEPARATOR = '&';
var EXPIRED = '1';
function getExpiredSessionState() {
    return {
        isExpired: EXPIRED,
    };
}
function isSessionInNotStartedState(session) {
    return isEmptyObject(session);
}
function isSessionStarted(session) {
    return !isSessionInNotStartedState(session);
}
function isSessionInExpiredState(session) {
    return session.isExpired !== undefined || !isActiveSession(session);
}
// An active session is a session in either `Tracked` or `NotTracked` state
function isActiveSession(sessionState) {
    // created and expire can be undefined for versions which was not storing them
    // these checks could be removed when older versions will not be available/live anymore
    return ((sessionState.created === undefined || dateNow() - Number(sessionState.created) < SESSION_TIME_OUT_DELAY) &&
        (sessionState.expire === undefined || dateNow() < Number(sessionState.expire)));
}
function expandSessionState(session) {
    session.expire = String(dateNow() + SESSION_EXPIRATION_DELAY);
}
function toSessionString(session) {
    return objectEntries(session)
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        return "".concat(key, "=").concat(value);
    })
        .join(SESSION_ENTRY_SEPARATOR);
}
function toSessionState(sessionString) {
    var session = {};
    if (isValidSessionString(sessionString)) {
        sessionString.split(SESSION_ENTRY_SEPARATOR).forEach(function (entry) {
            var matches = SESSION_ENTRY_REGEXP.exec(entry);
            if (matches !== null) {
                var key = matches[1], value = matches[2];
                session[key] = value;
            }
        });
    }
    return session;
}
function isValidSessionString(sessionString) {
    return (!!sessionString &&
        (sessionString.indexOf(SESSION_ENTRY_SEPARATOR) !== -1 || SESSION_ENTRY_REGEXP.test(sessionString)));
}

var OLD_SESSION_COOKIE_NAME = '_dd';
var OLD_RUM_COOKIE_NAME = '_dd_r';
var OLD_LOGS_COOKIE_NAME = '_dd_l';
// duplicate values to avoid dependency issues
var RUM_SESSION_KEY = 'rum';
var LOGS_SESSION_KEY$1 = 'logs';
/**
 * This migration should remain in the codebase as long as older versions are available/live
 * to allow older sdk versions to be upgraded to newer versions without compatibility issues.
 */
function tryOldCookiesMigration(cookieStoreStrategy) {
    var sessionString = getInitCookie(SESSION_STORE_KEY);
    if (!sessionString) {
        var oldSessionId = getInitCookie(OLD_SESSION_COOKIE_NAME);
        var oldRumType = getInitCookie(OLD_RUM_COOKIE_NAME);
        var oldLogsType = getInitCookie(OLD_LOGS_COOKIE_NAME);
        var session = {};
        if (oldSessionId) {
            session.id = oldSessionId;
        }
        if (oldLogsType && /^[01]$/.test(oldLogsType)) {
            session[LOGS_SESSION_KEY$1] = oldLogsType;
        }
        if (oldRumType && /^[012]$/.test(oldRumType)) {
            session[RUM_SESSION_KEY] = oldRumType;
        }
        if (isSessionStarted(session)) {
            expandSessionState(session);
            cookieStoreStrategy.persistSession(session);
        }
    }
}

function selectCookieStrategy(initConfiguration) {
    var cookieOptions = buildCookieOptions(initConfiguration);
    return areCookiesAuthorized(cookieOptions) ? { type: 'Cookie', cookieOptions: cookieOptions } : undefined;
}
function initCookieStrategy(cookieOptions) {
    var cookieStore = {
        /**
         * Lock strategy allows mitigating issues due to concurrent access to cookie.
         * This issue concerns only chromium browsers and enabling this on firefox increases cookie write failures.
         */
        isLockEnabled: isChromium(),
        persistSession: persistSessionCookie(cookieOptions),
        retrieveSession: retrieveSessionCookie,
        expireSession: function () { return expireSessionCookie(cookieOptions); },
    };
    tryOldCookiesMigration(cookieStore);
    return cookieStore;
}
function persistSessionCookie(options) {
    return function (session) {
        setCookie(SESSION_STORE_KEY, toSessionString(session), SESSION_EXPIRATION_DELAY, options);
    };
}
function expireSessionCookie(options) {
    setCookie(SESSION_STORE_KEY, toSessionString(getExpiredSessionState()), SESSION_TIME_OUT_DELAY, options);
}
function retrieveSessionCookie() {
    var sessionString = getCookie(SESSION_STORE_KEY);
    return toSessionState(sessionString);
}
function buildCookieOptions(initConfiguration) {
    var cookieOptions = {};
    cookieOptions.secure =
        !!initConfiguration.useSecureSessionCookie ||
            !!initConfiguration.usePartitionedCrossSiteSessionCookie ||
            !!initConfiguration.useCrossSiteSessionCookie;
    cookieOptions.crossSite =
        !!initConfiguration.usePartitionedCrossSiteSessionCookie || !!initConfiguration.useCrossSiteSessionCookie;
    cookieOptions.partitioned = !!initConfiguration.usePartitionedCrossSiteSessionCookie;
    if (initConfiguration.trackSessionAcrossSubdomains) {
        cookieOptions.domain = getCurrentSite();
    }
    return cookieOptions;
}

var LOCAL_STORAGE_TEST_KEY = '_dd_test_';
function selectLocalStorageStrategy() {
    try {
        var id = generateUUID();
        var testKey = "".concat(LOCAL_STORAGE_TEST_KEY).concat(id);
        localStorage.setItem(testKey, id);
        var retrievedId = localStorage.getItem(testKey);
        localStorage.removeItem(testKey);
        return id === retrievedId ? { type: 'LocalStorage' } : undefined;
    }
    catch (e) {
        return undefined;
    }
}
function initLocalStorageStrategy() {
    return {
        isLockEnabled: false,
        persistSession: persistInLocalStorage,
        retrieveSession: retrieveSessionFromLocalStorage,
        expireSession: expireSessionFromLocalStorage,
    };
}
function persistInLocalStorage(sessionState) {
    localStorage.setItem(SESSION_STORE_KEY, toSessionString(sessionState));
}
function retrieveSessionFromLocalStorage() {
    var sessionString = localStorage.getItem(SESSION_STORE_KEY);
    return toSessionState(sessionString);
}
function expireSessionFromLocalStorage() {
    persistInLocalStorage(getExpiredSessionState());
}

var LOCK_RETRY_DELAY = 10;
var LOCK_MAX_TRIES = 100;
var bufferedOperations = [];
var ongoingOperations;
function processSessionStoreOperations(operations, sessionStoreStrategy, numberOfRetries) {
    var _a;
    if (numberOfRetries === void 0) { numberOfRetries = 0; }
    var isLockEnabled = sessionStoreStrategy.isLockEnabled, persistSession = sessionStoreStrategy.persistSession, expireSession = sessionStoreStrategy.expireSession;
    var persistWithLock = function (session) { return persistSession(assign({}, session, { lock: currentLock })); };
    var retrieveStore = function () {
        var session = sessionStoreStrategy.retrieveSession();
        var lock = session.lock;
        if (session.lock) {
            delete session.lock;
        }
        return {
            session: session,
            lock: lock,
        };
    };
    if (!ongoingOperations) {
        ongoingOperations = operations;
    }
    if (operations !== ongoingOperations) {
        bufferedOperations.push(operations);
        return;
    }
    if (isLockEnabled && numberOfRetries >= LOCK_MAX_TRIES) {
        next(sessionStoreStrategy);
        return;
    }
    var currentLock;
    var currentStore = retrieveStore();
    if (isLockEnabled) {
        // if someone has lock, retry later
        if (currentStore.lock) {
            retryLater(operations, sessionStoreStrategy, numberOfRetries);
            return;
        }
        // acquire lock
        currentLock = generateUUID();
        persistWithLock(currentStore.session);
        // if lock is not acquired, retry later
        currentStore = retrieveStore();
        if (currentStore.lock !== currentLock) {
            retryLater(operations, sessionStoreStrategy, numberOfRetries);
            return;
        }
    }
    var processedSession = operations.process(currentStore.session);
    if (isLockEnabled) {
        // if lock corrupted after process, retry later
        currentStore = retrieveStore();
        if (currentStore.lock !== currentLock) {
            retryLater(operations, sessionStoreStrategy, numberOfRetries);
            return;
        }
    }
    if (processedSession) {
        if (isSessionInExpiredState(processedSession)) {
            expireSession();
        }
        else {
            expandSessionState(processedSession);
            isLockEnabled ? persistWithLock(processedSession) : persistSession(processedSession);
        }
    }
    if (isLockEnabled) {
        // correctly handle lock around expiration would require to handle this case properly at several levels
        // since we don't have evidence of lock issues around expiration, let's just not do the corruption check for it
        if (!(processedSession && isSessionInExpiredState(processedSession))) {
            // if lock corrupted after persist, retry later
            currentStore = retrieveStore();
            if (currentStore.lock !== currentLock) {
                retryLater(operations, sessionStoreStrategy, numberOfRetries);
                return;
            }
            persistSession(currentStore.session);
            processedSession = currentStore.session;
        }
    }
    // call after even if session is not persisted in order to perform operations on
    // up-to-date session state value => the value could have been modified by another tab
    (_a = operations.after) === null || _a === void 0 ? void 0 : _a.call(operations, processedSession || currentStore.session);
    next(sessionStoreStrategy);
}
function retryLater(operations, sessionStore, currentNumberOfRetries) {
    setTimeout(function () {
        processSessionStoreOperations(operations, sessionStore, currentNumberOfRetries + 1);
    }, LOCK_RETRY_DELAY);
}
function next(sessionStore) {
    ongoingOperations = undefined;
    var nextOperations = bufferedOperations.shift();
    if (nextOperations) {
        processSessionStoreOperations(nextOperations, sessionStore);
    }
}

/**
 * Every second, the storage will be polled to check for any change that can occur
 * to the session state in another browser tab, or another window.
 * This value has been determined from our previous cookie-only implementation.
 */
var STORAGE_POLL_DELAY = ONE_SECOND;
/**
 * Checks if cookies are available as the preferred storage
 * Else, checks if LocalStorage is allowed and available
 */
function selectSessionStoreStrategyType(initConfiguration) {
    var sessionStoreStrategyType = selectCookieStrategy(initConfiguration);
    if (!sessionStoreStrategyType && initConfiguration.allowFallbackToLocalStorage) {
        sessionStoreStrategyType = selectLocalStorageStrategy();
    }
    return sessionStoreStrategyType;
}
/**
 * Different session concepts:
 * - tracked, the session has an id and is updated along the user navigation
 * - not tracked, the session does not have an id but it is updated along the user navigation
 * - inactive, no session in store or session expired, waiting for a renew session
 */
function startSessionStore(sessionStoreStrategyType, productKey, computeSessionState) {
    var renewObservable = new Observable();
    var expireObservable = new Observable();
    var sessionStoreStrategy = sessionStoreStrategyType.type === 'Cookie'
        ? initCookieStrategy(sessionStoreStrategyType.cookieOptions)
        : initLocalStorageStrategy();
    var expireSession = sessionStoreStrategy.expireSession;
    var watchSessionTimeoutId = setInterval(watchSession, STORAGE_POLL_DELAY);
    var sessionCache;
    startSession();
    var _a = throttle(function () {
        processSessionStoreOperations({
            process: function (sessionState) {
                if (isSessionInNotStartedState(sessionState)) {
                    return;
                }
                var synchronizedSession = synchronizeSession(sessionState);
                expandOrRenewSessionState(synchronizedSession);
                return synchronizedSession;
            },
            after: function (sessionState) {
                if (isSessionStarted(sessionState) && !hasSessionInCache()) {
                    renewSessionInCache(sessionState);
                }
                sessionCache = sessionState;
            },
        }, sessionStoreStrategy);
    }, STORAGE_POLL_DELAY), throttledExpandOrRenewSession = _a.throttled, cancelExpandOrRenewSession = _a.cancel;
    function expandSession() {
        processSessionStoreOperations({
            process: function (sessionState) { return (hasSessionInCache() ? synchronizeSession(sessionState) : undefined); },
        }, sessionStoreStrategy);
    }
    /**
     * allows two behaviors:
     * - if the session is active, synchronize the session cache without updating the session store
     * - if the session is not active, clear the session store and expire the session cache
     */
    function watchSession() {
        processSessionStoreOperations({
            process: function (sessionState) { return (isSessionInExpiredState(sessionState) ? getExpiredSessionState() : undefined); },
            after: synchronizeSession,
        }, sessionStoreStrategy);
    }
    function synchronizeSession(sessionState) {
        if (isSessionInExpiredState(sessionState)) {
            sessionState = getExpiredSessionState();
        }
        if (hasSessionInCache()) {
            if (isSessionInCacheOutdated(sessionState)) {
                expireSessionInCache();
            }
            else {
                sessionCache = sessionState;
            }
        }
        return sessionState;
    }
    function startSession() {
        processSessionStoreOperations({
            process: function (sessionState) {
                if (isSessionInNotStartedState(sessionState)) {
                    return getExpiredSessionState();
                }
            },
            after: function (sessionState) {
                sessionCache = sessionState;
            },
        }, sessionStoreStrategy);
    }
    function expandOrRenewSessionState(sessionState) {
        if (isSessionInNotStartedState(sessionState)) {
            return false;
        }
        var _a = computeSessionState(sessionState[productKey]), trackingType = _a.trackingType, isTracked = _a.isTracked;
        sessionState[productKey] = trackingType;
        delete sessionState.isExpired;
        if (isTracked && !sessionState.id) {
            sessionState.id = generateUUID();
            sessionState.created = String(dateNow());
        }
    }
    function hasSessionInCache() {
        return sessionCache[productKey] !== undefined;
    }
    function isSessionInCacheOutdated(sessionState) {
        return sessionCache.id !== sessionState.id || sessionCache[productKey] !== sessionState[productKey];
    }
    function expireSessionInCache() {
        sessionCache = getExpiredSessionState();
        expireObservable.notify();
    }
    function renewSessionInCache(sessionState) {
        sessionCache = sessionState;
        renewObservable.notify();
    }
    return {
        expandOrRenewSession: throttledExpandOrRenewSession,
        expandSession: expandSession,
        getSession: function () { return sessionCache; },
        renewObservable: renewObservable,
        expireObservable: expireObservable,
        restartSession: startSession,
        expire: function () {
            cancelExpandOrRenewSession();
            expireSession();
            synchronizeSession(getExpiredSessionState());
        },
        stop: function () {
            clearInterval(watchSessionTimeoutId);
        },
    };
}

var TrackingConsent = {
    GRANTED: 'granted',
    NOT_GRANTED: 'not-granted',
};
function createTrackingConsentState(currentConsent) {
    var observable = new Observable();
    return {
        tryToInit: function (trackingConsent) {
            if (!currentConsent) {
                currentConsent = trackingConsent;
            }
        },
        update: function (trackingConsent) {
            currentConsent = trackingConsent;
            observable.notify();
        },
        isGranted: function () {
            return currentConsent === TrackingConsent.GRANTED;
        },
        observable: observable,
    };
}

/**
 * Custom implementation of JSON.stringify that ignores some toJSON methods. We need to do that
 * because some sites badly override toJSON on certain objects. Removing all toJSON methods from
 * nested values would be too costly, so we just detach them from the root value, and native classes
 * used to build JSON values (Array and Object).
 *
 * Note: this still assumes that JSON.stringify is correct.
 */
function jsonStringify(value, replacer, space) {
    if (typeof value !== 'object' || value === null) {
        return JSON.stringify(value);
    }
    // Note: The order matter here. We need to detach toJSON methods on parent classes before their
    // subclasses.
    var restoreObjectPrototypeToJson = detachToJsonMethod(Object.prototype);
    var restoreArrayPrototypeToJson = detachToJsonMethod(Array.prototype);
    var restoreValuePrototypeToJson = detachToJsonMethod(Object.getPrototypeOf(value));
    var restoreValueToJson = detachToJsonMethod(value);
    try {
        return JSON.stringify(value, replacer, space);
    }
    catch (_a) {
        return '<error: unable to serialize object>';
    }
    finally {
        restoreObjectPrototypeToJson();
        restoreArrayPrototypeToJson();
        restoreValuePrototypeToJson();
        restoreValueToJson();
    }
}
function detachToJsonMethod(value) {
    var object = value;
    var objectToJson = object.toJSON;
    if (objectToJson) {
        delete object.toJSON;
        return function () {
            object.toJSON = objectToJson;
        };
    }
    return noop;
}

function normalizeUrl(url) {
    return buildUrl(url, location.href).href;
}
function buildUrl(url, base) {
    var supportedURL = getSupportedUrl();
    if (supportedURL) {
        try {
            return base !== undefined ? new supportedURL(url, base) : new supportedURL(url);
        }
        catch (error) {
            throw new Error("Failed to construct URL: ".concat(String(error), " ").concat(jsonStringify({ url: url, base: base })));
        }
    }
    if (base === undefined && !/:/.test(url)) {
        throw new Error("Invalid URL: '".concat(url, "'"));
    }
    var doc = document;
    var anchorElement = doc.createElement('a');
    if (base !== undefined) {
        doc = document.implementation.createHTMLDocument('');
        var baseElement = doc.createElement('base');
        baseElement.href = base;
        doc.head.appendChild(baseElement);
        doc.body.appendChild(anchorElement);
    }
    anchorElement.href = url;
    return anchorElement;
}
var originalURL = URL;
var isURLSupported;
function getSupportedUrl() {
    if (isURLSupported === undefined) {
        try {
            var url = new originalURL('http://test/path');
            isURLSupported = url.href === 'http://test/path';
        }
        catch (_a) {
            isURLSupported = false;
        }
    }
    return isURLSupported ? originalURL : undefined;
}

var INTAKE_SITE_STAGING = 'datad0g.com';
var INTAKE_SITE_FED_STAGING = 'dd0g-gov.com';
var INTAKE_SITE_US1 = 'datadoghq.com';
var INTAKE_SITE_US1_FED = 'ddog-gov.com';
var PCI_INTAKE_HOST_US1 = 'pci.browser-intake-datadoghq.com';

function createEndpointBuilder(initConfiguration, trackType, configurationTags) {
    var buildUrlWithParameters = createEndpointUrlWithParametersBuilder(initConfiguration, trackType);
    return {
        build: function (api, payload) {
            var parameters = buildEndpointParameters(initConfiguration, trackType, configurationTags, api, payload);
            return buildUrlWithParameters(parameters);
        },
        urlPrefix: buildUrlWithParameters(''),
        trackType: trackType,
    };
}
/**
 * Create a function used to build a full endpoint url from provided parameters. The goal of this
 * function is to pre-compute some parts of the URL to avoid re-computing everything on every
 * request, as only parameters are changing.
 */
function createEndpointUrlWithParametersBuilder(initConfiguration, trackType) {
    var path = "/api/v2/".concat(trackType);
    var proxy = initConfiguration.proxy;
    if (typeof proxy === 'string') {
        var normalizedProxyUrl_1 = normalizeUrl(proxy);
        return function (parameters) { return "".concat(normalizedProxyUrl_1, "?ddforward=").concat(encodeURIComponent("".concat(path, "?").concat(parameters))); };
    }
    if (typeof proxy === 'function') {
        return function (parameters) { return proxy({ path: path, parameters: parameters }); };
    }
    var host = buildEndpointHost(trackType, initConfiguration);
    return function (parameters) { return "https://".concat(host).concat(path, "?").concat(parameters); };
}
function buildEndpointHost(trackType, initConfiguration) {
    var _a = initConfiguration.site, site = _a === void 0 ? INTAKE_SITE_US1 : _a, internalAnalyticsSubdomain = initConfiguration.internalAnalyticsSubdomain;
    if (trackType === 'logs' && initConfiguration.usePciIntake && site === INTAKE_SITE_US1) {
        return PCI_INTAKE_HOST_US1;
    }
    if (internalAnalyticsSubdomain && site === INTAKE_SITE_US1) {
        return "".concat(internalAnalyticsSubdomain, ".").concat(INTAKE_SITE_US1);
    }
    if (site === INTAKE_SITE_FED_STAGING) {
        return "http-intake.logs.".concat(site);
    }
    var domainParts = site.split('.');
    var extension = domainParts.pop();
    return "browser-intake-".concat(domainParts.join('-'), ".").concat(extension);
}
/**
 * Build parameters to be used for an intake request. Parameters should be re-built for each
 * request, as they change randomly.
 */
function buildEndpointParameters(_a, trackType, configurationTags, api, _b) {
    var clientToken = _a.clientToken, internalAnalyticsSubdomain = _a.internalAnalyticsSubdomain;
    var retry = _b.retry, encoding = _b.encoding;
    var tags = ["sdk_version:".concat("5.19.0"), "api:".concat(api)].concat(configurationTags);
    if (retry) {
        tags.push("retry_count:".concat(retry.count), "retry_after:".concat(retry.lastFailureStatus));
    }
    var parameters = [
        'ddsource=browser',
        "ddtags=".concat(encodeURIComponent(tags.join(','))),
        "dd-api-key=".concat(clientToken),
        "dd-evp-origin-version=".concat(encodeURIComponent("5.19.0")),
        'dd-evp-origin=browser',
        "dd-request-id=".concat(generateUUID()),
    ];
    if (encoding) {
        parameters.push("dd-evp-encoding=".concat(encoding));
    }
    if (trackType === 'rum') {
        parameters.push("batch_time=".concat(timeStampNow()));
    }
    if (internalAnalyticsSubdomain) {
        parameters.reverse();
    }
    return parameters.join('&');
}

var TAG_SIZE_LIMIT = 200;
function buildTags(configuration) {
    var env = configuration.env, service = configuration.service, version = configuration.version, datacenter = configuration.datacenter;
    var tags = [];
    if (env) {
        tags.push(buildTag('env', env));
    }
    if (service) {
        tags.push(buildTag('service', service));
    }
    if (version) {
        tags.push(buildTag('version', version));
    }
    if (datacenter) {
        tags.push(buildTag('datacenter', datacenter));
    }
    return tags;
}
var FORBIDDEN_CHARACTERS = /[^a-z0-9_:./-]/;
function buildTag(key, rawValue) {
    // See https://docs.datadoghq.com/getting_started/tagging/#defining-tags for tags syntax. Note
    // that the backend may not follow the exact same rules, so we only want to display an informal
    // warning.
    var valueSizeLimit = TAG_SIZE_LIMIT - key.length - 1;
    if (rawValue.length > valueSizeLimit || FORBIDDEN_CHARACTERS.test(rawValue)) {
        display.warn("".concat(key, " value doesn't meet tag requirements and will be sanitized"));
    }
    // Let the backend do most of the sanitization, but still make sure multiple tags can't be crafted
    // by forging a value containing commas.
    var sanitizedValue = rawValue.replace(/,/g, '_');
    return "".concat(key, ":").concat(sanitizedValue);
}

function computeTransportConfiguration(initConfiguration) {
    var site = initConfiguration.site || INTAKE_SITE_US1;
    var tags = buildTags(initConfiguration);
    var endpointBuilders = computeEndpointBuilders(initConfiguration, tags);
    var intakeUrlPrefixes = computeIntakeUrlPrefixes(endpointBuilders, site);
    var replicaConfiguration = computeReplicaConfiguration(initConfiguration, intakeUrlPrefixes, tags);
    return assign({
        isIntakeUrl: function (url) { return intakeUrlPrefixes.some(function (intakeEndpoint) { return url.indexOf(intakeEndpoint) === 0; }); },
        replica: replicaConfiguration,
        site: site,
    }, endpointBuilders);
}
function computeEndpointBuilders(initConfiguration, tags) {
    return {
        logsEndpointBuilder: createEndpointBuilder(initConfiguration, 'logs', tags),
        rumEndpointBuilder: createEndpointBuilder(initConfiguration, 'rum', tags),
        sessionReplayEndpointBuilder: createEndpointBuilder(initConfiguration, 'replay', tags),
    };
}
function computeReplicaConfiguration(initConfiguration, intakeUrlPrefixes, tags) {
    if (!initConfiguration.replica) {
        return;
    }
    var replicaConfiguration = assign({}, initConfiguration, {
        site: INTAKE_SITE_US1,
        clientToken: initConfiguration.replica.clientToken,
    });
    var replicaEndpointBuilders = {
        logsEndpointBuilder: createEndpointBuilder(replicaConfiguration, 'logs', tags),
        rumEndpointBuilder: createEndpointBuilder(replicaConfiguration, 'rum', tags),
    };
    intakeUrlPrefixes.push.apply(intakeUrlPrefixes, objectValues(replicaEndpointBuilders).map(function (builder) { return builder.urlPrefix; }));
    return assign({ applicationId: initConfiguration.replica.applicationId }, replicaEndpointBuilders);
}
function computeIntakeUrlPrefixes(endpointBuilders, site) {
    var intakeUrlPrefixes = objectValues(endpointBuilders).map(function (builder) { return builder.urlPrefix; });
    if (site === INTAKE_SITE_US1) {
        intakeUrlPrefixes.push("https://".concat(PCI_INTAKE_HOST_US1, "/"));
    }
    return intakeUrlPrefixes;
}

var DOC_LINK = 'https://docs.datadoghq.com/getting_started/site/';
function checkIfString(tag, tagName) {
    if (tag !== undefined && tag !== null && typeof tag !== 'string') {
        display.error("".concat(tagName, " must be defined as a string"));
        return false;
    }
    return true;
}
function isDatadogSite(site) {
    return /(datadog|ddog|datad0g|dd0g)/.test(site);
}
function validateAndBuildConfiguration(initConfiguration) {
    var _a, _b, _c, _d, _e;
    if (!initConfiguration || !initConfiguration.clientToken) {
        display.error('Client Token is not configured, we will not send any data.');
        return;
    }
    if (initConfiguration.sessionSampleRate !== undefined && !isPercentage(initConfiguration.sessionSampleRate)) {
        display.error('Session Sample Rate should be a number between 0 and 100');
        return;
    }
    if (initConfiguration.telemetrySampleRate !== undefined && !isPercentage(initConfiguration.telemetrySampleRate)) {
        display.error('Telemetry Sample Rate should be a number between 0 and 100');
        return;
    }
    if (initConfiguration.telemetryConfigurationSampleRate !== undefined &&
        !isPercentage(initConfiguration.telemetryConfigurationSampleRate)) {
        display.error('Telemetry Configuration Sample Rate should be a number between 0 and 100');
        return;
    }
    if (initConfiguration.telemetryUsageSampleRate !== undefined &&
        !isPercentage(initConfiguration.telemetryUsageSampleRate)) {
        display.error('Telemetry Usage Sample Rate should be a number between 0 and 100');
        return;
    }
    if (!checkIfString(initConfiguration.version, 'Version')) {
        return;
    }
    if (!checkIfString(initConfiguration.env, 'Env')) {
        return;
    }
    if (!checkIfString(initConfiguration.service, 'Service')) {
        return;
    }
    if (initConfiguration.trackingConsent !== undefined &&
        !objectHasValue(TrackingConsent, initConfiguration.trackingConsent)) {
        display.error('Tracking Consent should be either "granted" or "not-granted"');
        return;
    }
    if (initConfiguration.site && !isDatadogSite(initConfiguration.site)) {
        display.error("Site should be a valid Datadog site. Learn more here: ".concat(DOC_LINK, "."));
        return;
    }
    // Set the experimental feature flags as early as possible, so we can use them in most places
    if (Array.isArray(initConfiguration.enableExperimentalFeatures)) {
        addExperimentalFeatures(initConfiguration.enableExperimentalFeatures.filter(function (flag) {
            return objectHasValue(ExperimentalFeature, flag);
        }));
    }
    return assign({
        beforeSend: initConfiguration.beforeSend && catchUserErrors(initConfiguration.beforeSend, 'beforeSend threw an error:'),
        sessionStoreStrategyType: selectSessionStoreStrategyType(initConfiguration),
        sessionSampleRate: (_a = initConfiguration.sessionSampleRate) !== null && _a !== void 0 ? _a : 100,
        telemetrySampleRate: (_b = initConfiguration.telemetrySampleRate) !== null && _b !== void 0 ? _b : 20,
        telemetryConfigurationSampleRate: (_c = initConfiguration.telemetryConfigurationSampleRate) !== null && _c !== void 0 ? _c : 5,
        telemetryUsageSampleRate: (_d = initConfiguration.telemetryUsageSampleRate) !== null && _d !== void 0 ? _d : 5,
        service: initConfiguration.service || undefined,
        silentMultipleInit: !!initConfiguration.silentMultipleInit,
        allowUntrustedEvents: !!initConfiguration.allowUntrustedEvents,
        trackingConsent: (_e = initConfiguration.trackingConsent) !== null && _e !== void 0 ? _e : TrackingConsent.GRANTED,
        /**
         * beacon payload max queue size implementation is 64kb
         * ensure that we leave room for logs, rum and potential other users
         */
        batchBytesLimit: 16 * ONE_KIBI_BYTE,
        eventRateLimiterThreshold: 3000,
        maxTelemetryEventsPerPage: 15,
        /**
         * flush automatically, aim to be lower than ALB connection timeout
         * to maximize connection reuse.
         */
        flushTimeout: (30 * ONE_SECOND),
        /**
         * Logs intake limit
         */
        batchMessagesLimit: 50,
        messageBytesLimit: 256 * ONE_KIBI_BYTE,
    }, computeTransportConfiguration(initConfiguration));
}
function serializeConfiguration(initConfiguration) {
    return {
        session_sample_rate: initConfiguration.sessionSampleRate,
        telemetry_sample_rate: initConfiguration.telemetrySampleRate,
        telemetry_configuration_sample_rate: initConfiguration.telemetryConfigurationSampleRate,
        telemetry_usage_sample_rate: initConfiguration.telemetryUsageSampleRate,
        use_before_send: !!initConfiguration.beforeSend,
        use_cross_site_session_cookie: initConfiguration.useCrossSiteSessionCookie,
        use_partitioned_cross_site_session_cookie: initConfiguration.usePartitionedCrossSiteSessionCookie,
        use_secure_session_cookie: initConfiguration.useSecureSessionCookie,
        use_proxy: !!initConfiguration.proxy,
        silent_multiple_init: initConfiguration.silentMultipleInit,
        track_session_across_subdomains: initConfiguration.trackSessionAcrossSubdomains,
        allow_fallback_to_local_storage: !!initConfiguration.allowFallbackToLocalStorage,
        store_contexts_across_pages: !!initConfiguration.storeContextsAcrossPages,
        allow_untrusted_events: !!initConfiguration.allowUntrustedEvents,
        tracking_consent: initConfiguration.trackingConsent,
    };
}

/**
 * Instruments a method on a object, calling the given callback before the original method is
 * invoked. The callback receives an object with information about the method call.
 *
 * This function makes sure that we are "good citizens" regarding third party instrumentations: when
 * removing the instrumentation, the original method is usually restored, but if a third party
 * instrumentation was set after ours, we keep it in place and just replace our instrumentation with
 * a noop.
 *
 * Note: it is generally better to instrument methods that are "owned" by the object instead of ones
 * that are inherited from the prototype chain. Example:
 * * do:    `instrumentMethod(Array.prototype, 'push', ...)`
 * * don't: `instrumentMethod([], 'push', ...)`
 *
 * This method is also used to set event handler properties (ex: window.onerror = ...), as it has
 * the same requirements as instrumenting a method:
 * * if the event handler is already set by a third party, we need to call it and not just blindly
 * override it.
 * * if the event handler is set by a third party after us, we need to keep it in place when
 * removing ours.
 *
 * @example
 *
 *  instrumentMethod(window, 'fetch', ({ target, parameters, onPostCall }) => {
 *    console.log('Before calling fetch on', target, 'with parameters', parameters)
 *
 *    onPostCall((result) => {
 *      console.log('After fetch calling on', target, 'with parameters', parameters, 'and result', result)
 *    })
 *  })
 */
function instrumentMethod(targetPrototype, method, onPreCall) {
    var original = targetPrototype[method];
    if (typeof original !== 'function') {
        if (startsWith(method, 'on')) {
            original = noop;
        }
        else {
            return { stop: noop };
        }
    }
    var instrumentation = createInstrumentedMethod(original, onPreCall);
    var instrumentationWrapper = function () {
        if (typeof instrumentation !== 'function') {
            return undefined;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        return instrumentation.apply(this, arguments);
    };
    targetPrototype[method] = instrumentationWrapper;
    return {
        stop: function () {
            if (targetPrototype[method] === instrumentationWrapper) {
                targetPrototype[method] = original;
            }
            else {
                instrumentation = original;
            }
        },
    };
}
function createInstrumentedMethod(original, onPreCall) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return function () {
        var parameters = arrayFrom(arguments);
        var postCallCallback;
        callMonitored(onPreCall, null, [
            {
                target: this,
                parameters: parameters,
                onPostCall: function (callback) {
                    postCallCallback = callback;
                },
            },
        ]);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        var result = original.apply(this, parameters);
        if (postCallCallback) {
            callMonitored(postCallCallback, null, [result]);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return result;
    };
}

/**
 * Cross-browser stack trace computation.
 *
 * Reference implementation: https://github.com/csnover/TraceKit/blob/04530298073c3823de72deb0b97e7b38ca7bcb59/tracekit.js
 */
var UNKNOWN_FUNCTION = '?';
function computeStackTrace(ex) {
    var stack = [];
    var stackProperty = tryToGetString(ex, 'stack');
    var exString = String(ex);
    if (stackProperty && startsWith(stackProperty, exString)) {
        stackProperty = stackProperty.slice(exString.length);
    }
    if (stackProperty) {
        stackProperty.split('\n').forEach(function (line) {
            var stackFrame = parseChromeLine(line) || parseChromeAnonymousLine(line) || parseWinLine(line) || parseGeckoLine(line);
            if (stackFrame) {
                if (!stackFrame.func && stackFrame.line) {
                    stackFrame.func = UNKNOWN_FUNCTION;
                }
                stack.push(stackFrame);
            }
        });
    }
    return {
        message: tryToGetString(ex, 'message'),
        name: tryToGetString(ex, 'name'),
        stack: stack,
    };
}
var fileUrl = '((?:file|https?|blob|chrome-extension|native|eval|webpack|snippet|<anonymous>|\\w+\\.|\\/).*?)';
var filePosition = '(?::(\\d+))';
var CHROME_LINE_RE = new RegExp("^\\s*at (.*?) ?\\(".concat(fileUrl).concat(filePosition, "?").concat(filePosition, "?\\)?\\s*$"), 'i');
var CHROME_EVAL_RE = new RegExp("\\((\\S*)".concat(filePosition).concat(filePosition, "\\)"));
function parseChromeLine(line) {
    var parts = CHROME_LINE_RE.exec(line);
    if (!parts) {
        return;
    }
    var isNative = parts[2] && parts[2].indexOf('native') === 0; // start of line
    var isEval = parts[2] && parts[2].indexOf('eval') === 0; // start of line
    var submatch = CHROME_EVAL_RE.exec(parts[2]);
    if (isEval && submatch) {
        // throw out eval line/column and use top-most line/column number
        parts[2] = submatch[1]; // url
        parts[3] = submatch[2]; // line
        parts[4] = submatch[3]; // column
    }
    return {
        args: isNative ? [parts[2]] : [],
        column: parts[4] ? +parts[4] : undefined,
        func: parts[1] || UNKNOWN_FUNCTION,
        line: parts[3] ? +parts[3] : undefined,
        url: !isNative ? parts[2] : undefined,
    };
}
var CHROME_ANONYMOUS_FUNCTION_RE = new RegExp("^\\s*at ?".concat(fileUrl).concat(filePosition, "?").concat(filePosition, "??\\s*$"), 'i');
function parseChromeAnonymousLine(line) {
    var parts = CHROME_ANONYMOUS_FUNCTION_RE.exec(line);
    if (!parts) {
        return;
    }
    return {
        args: [],
        column: parts[3] ? +parts[3] : undefined,
        func: UNKNOWN_FUNCTION,
        line: parts[2] ? +parts[2] : undefined,
        url: parts[1],
    };
}
var WINJS_LINE_RE = /^\s*at (?:((?:\[object object\])?.+) )?\(?((?:file|ms-appx|https?|webpack|blob):.*?):(\d+)(?::(\d+))?\)?\s*$/i;
function parseWinLine(line) {
    var parts = WINJS_LINE_RE.exec(line);
    if (!parts) {
        return;
    }
    return {
        args: [],
        column: parts[4] ? +parts[4] : undefined,
        func: parts[1] || UNKNOWN_FUNCTION,
        line: +parts[3],
        url: parts[2],
    };
}
var GECKO_LINE_RE = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)((?:file|https?|blob|chrome|webpack|resource|capacitor|\[native).*?|[^@]*bundle)(?::(\d+))?(?::(\d+))?\s*$/i;
var GECKO_EVAL_RE = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i;
function parseGeckoLine(line) {
    var parts = GECKO_LINE_RE.exec(line);
    if (!parts) {
        return;
    }
    var isEval = parts[3] && parts[3].indexOf(' > eval') > -1;
    var submatch = GECKO_EVAL_RE.exec(parts[3]);
    if (isEval && submatch) {
        // throw out eval line/column and use top-most line number
        parts[3] = submatch[1];
        parts[4] = submatch[2];
        parts[5] = undefined; // no column when eval
    }
    return {
        args: parts[2] ? parts[2].split(',') : [],
        column: parts[5] ? +parts[5] : undefined,
        func: parts[1] || UNKNOWN_FUNCTION,
        line: parts[4] ? +parts[4] : undefined,
        url: parts[3],
    };
}
function tryToGetString(candidate, property) {
    if (typeof candidate !== 'object' || !candidate || !(property in candidate)) {
        return undefined;
    }
    var value = candidate[property];
    return typeof value === 'string' ? value : undefined;
}
function computeStackTraceFromOnErrorMessage(messageObj, url, line, column) {
    var stack = [{ url: url, column: column, line: line }];
    var _a = tryToParseMessage(messageObj), name = _a.name, message = _a.message;
    return {
        name: name,
        message: message,
        stack: stack,
    };
}
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Error_types
var ERROR_TYPES_RE = /^(?:[Uu]ncaught (?:exception: )?)?(?:((?:Eval|Internal|Range|Reference|Syntax|Type|URI|)Error): )?([\s\S]*)$/;
function tryToParseMessage(messageObj) {
    var _a;
    var name;
    var message;
    if ({}.toString.call(messageObj) === '[object String]') {
        _a = ERROR_TYPES_RE.exec(messageObj), name = _a[1], message = _a[2];
    }
    return { name: name, message: message };
}

// The maximum size of a single event is 256KiB. By default, we ensure that user-provided data
// going through sanitize fits inside our events, while leaving room for other contexts, metadata, ...
var SANITIZE_DEFAULT_MAX_CHARACTER_COUNT = 220 * ONE_KIBI_BYTE;
// Symbol for the root element of the JSONPath used for visited objects
var JSON_PATH_ROOT_ELEMENT = '$';
// When serializing (using JSON.stringify) a key of an object, { key: 42 } gets wrapped in quotes as "key".
// With the separator (:), we need to add 3 characters to the count.
var KEY_DECORATION_LENGTH = 3;
function sanitize(source, maxCharacterCount) {
    var _a;
    if (maxCharacterCount === void 0) { maxCharacterCount = SANITIZE_DEFAULT_MAX_CHARACTER_COUNT; }
    // Unbind any toJSON function we may have on [] or {} prototypes
    var restoreObjectPrototypeToJson = detachToJsonMethod(Object.prototype);
    var restoreArrayPrototypeToJson = detachToJsonMethod(Array.prototype);
    // Initial call to sanitizeProcessor - will populate containerQueue if source is an Array or a plain Object
    var containerQueue = [];
    var visitedObjectsWithPath = new WeakMap();
    var sanitizedData = sanitizeProcessor(source, JSON_PATH_ROOT_ELEMENT, undefined, containerQueue, visitedObjectsWithPath);
    var accumulatedCharacterCount = ((_a = JSON.stringify(sanitizedData)) === null || _a === void 0 ? void 0 : _a.length) || 0;
    if (accumulatedCharacterCount > maxCharacterCount) {
        warnOverCharacterLimit(maxCharacterCount, 'discarded', source);
        return undefined;
    }
    while (containerQueue.length > 0 && accumulatedCharacterCount < maxCharacterCount) {
        var containerToProcess = containerQueue.shift();
        var separatorLength = 0; // 0 for the first element, 1 for subsequent elements
        // Arrays and Objects have to be handled distinctly to ensure
        // we do not pick up non-numerical properties from Arrays
        if (Array.isArray(containerToProcess.source)) {
            for (var key = 0; key < containerToProcess.source.length; key++) {
                var targetData = sanitizeProcessor(containerToProcess.source[key], containerToProcess.path, key, containerQueue, visitedObjectsWithPath);
                if (targetData !== undefined) {
                    accumulatedCharacterCount += JSON.stringify(targetData).length;
                }
                else {
                    // When an element of an Array (targetData) is undefined, it is serialized as null:
                    // JSON.stringify([undefined]) => '[null]' - This accounts for 4 characters
                    accumulatedCharacterCount += 4;
                }
                accumulatedCharacterCount += separatorLength;
                separatorLength = 1;
                if (accumulatedCharacterCount > maxCharacterCount) {
                    warnOverCharacterLimit(maxCharacterCount, 'truncated', source);
                    break;
                }
                containerToProcess.target[key] = targetData;
            }
        }
        else {
            for (var key in containerToProcess.source) {
                if (Object.prototype.hasOwnProperty.call(containerToProcess.source, key)) {
                    var targetData = sanitizeProcessor(containerToProcess.source[key], containerToProcess.path, key, containerQueue, visitedObjectsWithPath);
                    // When a property of an object has an undefined value, it will be dropped during serialization:
                    // JSON.stringify({a:undefined}) => '{}'
                    if (targetData !== undefined) {
                        accumulatedCharacterCount +=
                            JSON.stringify(targetData).length + separatorLength + key.length + KEY_DECORATION_LENGTH;
                        separatorLength = 1;
                    }
                    if (accumulatedCharacterCount > maxCharacterCount) {
                        warnOverCharacterLimit(maxCharacterCount, 'truncated', source);
                        break;
                    }
                    containerToProcess.target[key] = targetData;
                }
            }
        }
    }
    // Rebind detached toJSON functions
    restoreObjectPrototypeToJson();
    restoreArrayPrototypeToJson();
    return sanitizedData;
}
/**
 * Internal function to factorize the process common to the
 * initial call to sanitize, and iterations for Arrays and Objects
 *
 */
function sanitizeProcessor(source, parentPath, key, queue, visitedObjectsWithPath) {
    // Start by handling toJSON, as we want to sanitize its output
    var sourceToSanitize = tryToApplyToJSON(source);
    if (!sourceToSanitize || typeof sourceToSanitize !== 'object') {
        return sanitizePrimitivesAndFunctions(sourceToSanitize);
    }
    var sanitizedSource = sanitizeObjects(sourceToSanitize);
    if (sanitizedSource !== '[Object]' && sanitizedSource !== '[Array]' && sanitizedSource !== '[Error]') {
        return sanitizedSource;
    }
    // Handle potential cyclic references
    // We need to use source as sourceToSanitize could be a reference to a new object
    // At this stage, we know the source is an object type
    var sourceAsObject = source;
    if (visitedObjectsWithPath.has(sourceAsObject)) {
        return "[Reference seen at ".concat(visitedObjectsWithPath.get(sourceAsObject), "]");
    }
    // Add processed source to queue
    var currentPath = key !== undefined ? "".concat(parentPath, ".").concat(key) : parentPath;
    var target = Array.isArray(sourceToSanitize) ? [] : {};
    visitedObjectsWithPath.set(sourceAsObject, currentPath);
    queue.push({ source: sourceToSanitize, target: target, path: currentPath });
    return target;
}
/**
 * Handles sanitization of simple, non-object types
 *
 */
function sanitizePrimitivesAndFunctions(value) {
    // BigInt cannot be serialized by JSON.stringify(), convert it to a string representation
    if (typeof value === 'bigint') {
        return "[BigInt] ".concat(value.toString());
    }
    // Functions cannot be serialized by JSON.stringify(). Moreover, if a faulty toJSON is present, it needs to be converted
    // so it won't prevent stringify from serializing later
    if (typeof value === 'function') {
        return "[Function] ".concat(value.name || 'unknown');
    }
    // JSON.stringify() does not serialize symbols.
    if (typeof value === 'symbol') {
        return "[Symbol] ".concat(value.description || value.toString());
    }
    return value;
}
/**
 * Handles sanitization of object types
 *
 * LIMITATIONS
 * - If a class defines a toStringTag Symbol, it will fall in the catch-all method and prevent enumeration of properties.
 * To avoid this, a toJSON method can be defined.
 * - IE11 does not return a distinct type for objects such as Map, WeakMap, ... These objects will pass through and their
 * properties enumerated if any.
 *
 */
function sanitizeObjects(value) {
    try {
        // Handle events - Keep a simple implementation to avoid breaking changes
        if (value instanceof Event) {
            return {
                isTrusted: value.isTrusted,
            };
        }
        // Handle all remaining object types in a generic way
        var result = Object.prototype.toString.call(value);
        var match = result.match(/\[object (.*)\]/);
        if (match && match[1]) {
            return "[".concat(match[1], "]");
        }
    }
    catch (_a) {
        // If the previous serialization attempts failed, and we cannot convert using
        // Object.prototype.toString, declare the value unserializable
    }
    return '[Unserializable]';
}
/**
 * Checks if a toJSON function exists and tries to execute it
 *
 */
function tryToApplyToJSON(value) {
    var object = value;
    if (object && typeof object.toJSON === 'function') {
        try {
            return object.toJSON();
        }
        catch (_a) {
            // If toJSON fails, we continue by trying to serialize the value manually
        }
    }
    return value;
}
/**
 * Helper function to display the warning when the accumulated character count is over the limit
 */
function warnOverCharacterLimit(maxCharacterCount, changeType, source) {
    display.warn("The data provided has been ".concat(changeType, " as it is over the limit of ").concat(maxCharacterCount, " characters:"), source);
}

/**
 * Creates a stacktrace without SDK internal frames.
 * Constraints:
 * - Has to be called at the utmost position of the call stack.
 * - No monitored function should encapsulate it, that is why we need to use callMonitored inside it.
 */
function createHandlingStack() {
    /**
     * Skip the two internal frames:
     * - SDK API (console.error, ...)
     * - this function
     * in order to keep only the user calls
     */
    var internalFramesToSkip = 2;
    var error = new Error();
    var formattedStack;
    // IE needs to throw the error to fill in the stack trace
    if (!error.stack) {
        try {
            throw error;
        }
        catch (e) {
        }
    }
    callMonitored(function () {
        var stackTrace = computeStackTrace(error);
        stackTrace.stack = stackTrace.stack.slice(internalFramesToSkip);
        formattedStack = toStackTraceString(stackTrace);
    });
    return formattedStack;
}
function toStackTraceString(stack) {
    var result = formatErrorMessage(stack);
    stack.stack.forEach(function (frame) {
        var func = frame.func === '?' ? '<anonymous>' : frame.func;
        var args = frame.args && frame.args.length > 0 ? "(".concat(frame.args.join(', '), ")") : '';
        var line = frame.line ? ":".concat(frame.line) : '';
        var column = frame.line && frame.column ? ":".concat(frame.column) : '';
        result += "\n  at ".concat(func).concat(args, " @ ").concat(frame.url).concat(line).concat(column);
    });
    return result;
}
function formatErrorMessage(stack) {
    return "".concat(stack.name || 'Error', ": ").concat(stack.message);
}

var NO_ERROR_STACK_PRESENT_MESSAGE = 'No stack, consider using an instance of Error';
function computeRawError(_a) {
    var stackTrace = _a.stackTrace, originalError = _a.originalError, handlingStack = _a.handlingStack, startClocks = _a.startClocks, nonErrorPrefix = _a.nonErrorPrefix, source = _a.source, handling = _a.handling;
    var isErrorInstance = originalError instanceof Error;
    var message = computeMessage(stackTrace, isErrorInstance, nonErrorPrefix, originalError);
    var stack = hasUsableStack(isErrorInstance, stackTrace)
        ? toStackTraceString(stackTrace)
        : NO_ERROR_STACK_PRESENT_MESSAGE;
    var causes = isErrorInstance ? flattenErrorCauses(originalError, source) : undefined;
    var type = stackTrace === null || stackTrace === void 0 ? void 0 : stackTrace.name;
    var fingerprint = tryToGetFingerprint(originalError);
    return {
        startClocks: startClocks,
        source: source,
        handling: handling,
        handlingStack: handlingStack,
        originalError: originalError,
        type: type,
        message: message,
        stack: stack,
        causes: causes,
        fingerprint: fingerprint,
    };
}
function computeMessage(stackTrace, isErrorInstance, nonErrorPrefix, originalError) {
    // Favor stackTrace message only if tracekit has really been able to extract something meaningful (message + name)
    // TODO rework tracekit integration to avoid scattering error building logic
    return (stackTrace === null || stackTrace === void 0 ? void 0 : stackTrace.message) && (stackTrace === null || stackTrace === void 0 ? void 0 : stackTrace.name)
        ? stackTrace.message
        : !isErrorInstance
            ? "".concat(nonErrorPrefix, " ").concat(jsonStringify(sanitize(originalError)))
            : 'Empty message';
}
function hasUsableStack(isErrorInstance, stackTrace) {
    if (stackTrace === undefined) {
        return false;
    }
    if (isErrorInstance) {
        return true;
    }
    // handle cases where tracekit return stack = [] or stack = [{url: undefined, line: undefined, column: undefined}]
    // TODO rework tracekit integration to avoid generating those unusable stack
    return stackTrace.stack.length > 0 && (stackTrace.stack.length > 1 || stackTrace.stack[0].url !== undefined);
}
function tryToGetFingerprint(originalError) {
    return originalError instanceof Error && 'dd_fingerprint' in originalError
        ? String(originalError.dd_fingerprint)
        : undefined;
}
function getFileFromStackTraceString(stack) {
    var _a;
    return (_a = /@ (.+)/.exec(stack)) === null || _a === void 0 ? void 0 : _a[1];
}
function flattenErrorCauses(error, parentSource) {
    var currentError = error;
    var causes = [];
    while ((currentError === null || currentError === void 0 ? void 0 : currentError.cause) instanceof Error && causes.length < 10) {
        var stackTrace = computeStackTrace(currentError.cause);
        causes.push({
            message: currentError.cause.message,
            source: parentSource,
            type: stackTrace === null || stackTrace === void 0 ? void 0 : stackTrace.name,
            stack: stackTrace && toStackTraceString(stackTrace),
        });
        currentError = currentError.cause;
    }
    return causes.length ? causes : undefined;
}

var ErrorSource = {
    AGENT: 'agent',
    CONSOLE: 'console',
    CUSTOM: 'custom',
    LOGGER: 'logger',
    NETWORK: 'network',
    SOURCE: 'source',
    REPORT: 'report',
};

function trackRuntimeError(errorObservable) {
    var handleRuntimeError = function (stackTrace, originalError) {
        var rawError = computeRawError({
            stackTrace: stackTrace,
            originalError: originalError,
            startClocks: clocksNow(),
            nonErrorPrefix: "Uncaught" /* NonErrorPrefix.UNCAUGHT */,
            source: ErrorSource.SOURCE,
            handling: "unhandled" /* ErrorHandling.UNHANDLED */,
        });
        errorObservable.notify(rawError);
    };
    var stopInstrumentingOnError = instrumentOnError(handleRuntimeError).stop;
    var stopInstrumentingOnUnhandledRejection = instrumentUnhandledRejection(handleRuntimeError).stop;
    return {
        stop: function () {
            stopInstrumentingOnError();
            stopInstrumentingOnUnhandledRejection();
        },
    };
}
function instrumentOnError(callback) {
    return instrumentMethod(window, 'onerror', function (_a) {
        var _b = _a.parameters, messageObj = _b[0], url = _b[1], line = _b[2], column = _b[3], errorObj = _b[4];
        var stackTrace;
        if (errorObj instanceof Error) {
            stackTrace = computeStackTrace(errorObj);
        }
        else {
            stackTrace = computeStackTraceFromOnErrorMessage(messageObj, url, line, column);
        }
        callback(stackTrace, errorObj !== null && errorObj !== void 0 ? errorObj : messageObj);
    });
}
function instrumentUnhandledRejection(callback) {
    return instrumentMethod(window, 'onunhandledrejection', function (_a) {
        var e = _a.parameters[0];
        var reason = e.reason || 'Empty reason';
        var stack = computeStackTrace(reason);
        callback(stack, reason);
    });
}

function makePublicApi(stub) {
    var publicApi = assign({
        version: "5.19.0",
        // This API method is intentionally not monitored, since the only thing executed is the
        // user-provided 'callback'.  All SDK usages executed in the callback should be monitored, and
        // we don't want to interfere with the user uncaught exceptions.
        onReady: function (callback) {
            callback();
        },
    }, stub);
    // Add a "hidden" property to set debug mode. We define it that way to hide it
    // as much as possible but of course it's not a real protection.
    Object.defineProperty(publicApi, '_setDebug', {
        get: function () {
            return setDebugMode;
        },
        enumerable: false,
    });
    return publicApi;
}
function defineGlobal(global, name, api) {
    var existingGlobalVariable = global[name];
    global[name] = api;
    if (existingGlobalVariable && existingGlobalVariable.q) {
        existingGlobalVariable.q.forEach(function (fn) { return catchUserErrors(fn, 'onReady callback threw an error:')(); });
    }
}

function displayAlreadyInitializedError(sdkName, initConfiguration) {
    if (!initConfiguration.silentMultipleInit) {
        display.error("".concat(sdkName, " is already initialized."));
    }
}

// We want to use a real enum (i.e. not a const enum) here, to be able to iterate over it to automatically add _ddIsTrusted in e2e tests
// eslint-disable-next-line no-restricted-syntax
var DOM_EVENT;
(function (DOM_EVENT) {
    DOM_EVENT["BEFORE_UNLOAD"] = "beforeunload";
    DOM_EVENT["CLICK"] = "click";
    DOM_EVENT["DBL_CLICK"] = "dblclick";
    DOM_EVENT["KEY_DOWN"] = "keydown";
    DOM_EVENT["LOAD"] = "load";
    DOM_EVENT["POP_STATE"] = "popstate";
    DOM_EVENT["SCROLL"] = "scroll";
    DOM_EVENT["TOUCH_START"] = "touchstart";
    DOM_EVENT["TOUCH_END"] = "touchend";
    DOM_EVENT["TOUCH_MOVE"] = "touchmove";
    DOM_EVENT["VISIBILITY_CHANGE"] = "visibilitychange";
    DOM_EVENT["PAGE_SHOW"] = "pageshow";
    DOM_EVENT["FREEZE"] = "freeze";
    DOM_EVENT["RESUME"] = "resume";
    DOM_EVENT["DOM_CONTENT_LOADED"] = "DOMContentLoaded";
    DOM_EVENT["POINTER_DOWN"] = "pointerdown";
    DOM_EVENT["POINTER_UP"] = "pointerup";
    DOM_EVENT["POINTER_CANCEL"] = "pointercancel";
    DOM_EVENT["HASH_CHANGE"] = "hashchange";
    DOM_EVENT["PAGE_HIDE"] = "pagehide";
    DOM_EVENT["MOUSE_DOWN"] = "mousedown";
    DOM_EVENT["MOUSE_UP"] = "mouseup";
    DOM_EVENT["MOUSE_MOVE"] = "mousemove";
    DOM_EVENT["FOCUS"] = "focus";
    DOM_EVENT["BLUR"] = "blur";
    DOM_EVENT["CONTEXT_MENU"] = "contextmenu";
    DOM_EVENT["RESIZE"] = "resize";
    DOM_EVENT["CHANGE"] = "change";
    DOM_EVENT["INPUT"] = "input";
    DOM_EVENT["PLAY"] = "play";
    DOM_EVENT["PAUSE"] = "pause";
    DOM_EVENT["SECURITY_POLICY_VIOLATION"] = "securitypolicyviolation";
    DOM_EVENT["SELECTION_CHANGE"] = "selectionchange";
    DOM_EVENT["STORAGE"] = "storage";
})(DOM_EVENT || (DOM_EVENT = {}));
/**
 * Add an event listener to an event target object (Window, Element, mock object...).  This provides
 * a few conveniences compared to using `element.addEventListener` directly:
 *
 * * supports IE11 by: using an option object only if needed and emulating the `once` option
 *
 * * wraps the listener with a `monitor` function
 *
 * * returns a `stop` function to remove the listener
 */
function addEventListener(configuration, eventTarget, eventName, listener, options) {
    return addEventListeners(configuration, eventTarget, [eventName], listener, options);
}
/**
 * Add event listeners to an event target object (Window, Element, mock object...).  This provides
 * a few conveniences compared to using `element.addEventListener` directly:
 *
 * * supports IE11 by: using an option object only if needed and emulating the `once` option
 *
 * * wraps the listener with a `monitor` function
 *
 * * returns a `stop` function to remove the listener
 *
 * * with `once: true`, the listener will be called at most once, even if different events are listened
 */
function addEventListeners(configuration, eventTarget, eventNames, listener, _a) {
    var _b = _a === void 0 ? {} : _a, once = _b.once, capture = _b.capture, passive = _b.passive;
    var listenerWithMonitor = monitor(function (event) {
        if (!event.isTrusted && !event.__ddIsTrusted && !configuration.allowUntrustedEvents) {
            return;
        }
        if (once) {
            stop();
        }
        listener(event);
    });
    var options = passive ? { capture: capture, passive: passive } : capture;
    var add = getZoneJsOriginalValue(eventTarget, 'addEventListener');
    eventNames.forEach(function (eventName) { return add.call(eventTarget, eventName, listenerWithMonitor, options); });
    function stop() {
        var remove = getZoneJsOriginalValue(eventTarget, 'removeEventListener');
        eventNames.forEach(function (eventName) { return remove.call(eventTarget, eventName, listenerWithMonitor, options); });
    }
    return {
        stop: stop,
    };
}

var RawReportType = {
    intervention: 'intervention',
    deprecation: 'deprecation',
    cspViolation: 'csp_violation',
};
function initReportObservable(configuration, apis) {
    var observables = [];
    if (includes(apis, RawReportType.cspViolation)) {
        observables.push(createCspViolationReportObservable(configuration));
    }
    var reportTypes = apis.filter(function (api) { return api !== RawReportType.cspViolation; });
    if (reportTypes.length) {
        observables.push(createReportObservable(reportTypes));
    }
    return mergeObservables.apply(void 0, observables);
}
function createReportObservable(reportTypes) {
    return new Observable(function (observable) {
        if (!window.ReportingObserver) {
            return;
        }
        var handleReports = monitor(function (reports, _) {
            return reports.forEach(function (report) {
                observable.notify(buildRawReportFromReport(report));
            });
        });
        var observer = new window.ReportingObserver(handleReports, {
            types: reportTypes,
            buffered: true,
        });
        observer.observe();
        return function () {
            observer.disconnect();
        };
    });
}
function createCspViolationReportObservable(configuration) {
    return new Observable(function (observable) {
        var stop = addEventListener(configuration, document, DOM_EVENT.SECURITY_POLICY_VIOLATION, function (event) {
            observable.notify(buildRawReportFromCspViolation(event));
        }).stop;
        return stop;
    });
}
function buildRawReportFromReport(report) {
    var type = report.type, body = report.body;
    return {
        type: type,
        subtype: body.id,
        message: "".concat(type, ": ").concat(body.message),
        originalReport: report,
        stack: buildStack(body.id, body.message, body.sourceFile, body.lineNumber, body.columnNumber),
    };
}
function buildRawReportFromCspViolation(event) {
    var type = RawReportType.cspViolation;
    var message = "'".concat(event.blockedURI, "' blocked by '").concat(event.effectiveDirective, "' directive");
    return {
        type: RawReportType.cspViolation,
        subtype: event.effectiveDirective,
        message: "".concat(type, ": ").concat(message),
        stack: buildStack(event.effectiveDirective, event.originalPolicy
            ? "".concat(message, " of the policy \"").concat(safeTruncate(event.originalPolicy, 100, '...'), "\"")
            : 'no policy', event.sourceFile, event.lineNumber, event.columnNumber),
        originalReport: event,
    };
}
function buildStack(name, message, sourceFile, lineNumber, columnNumber) {
    return sourceFile
        ? toStackTraceString({
            name: name,
            message: message,
            stack: [
                {
                    func: '?',
                    url: sourceFile,
                    line: lineNumber !== null && lineNumber !== void 0 ? lineNumber : undefined,
                    column: columnNumber !== null && columnNumber !== void 0 ? columnNumber : undefined,
                },
            ],
        })
        : undefined;
}

function sendToExtension(type, payload) {
    var callback = window.__ddBrowserSdkExtensionCallback;
    if (callback) {
        callback({ type: type, payload: payload });
    }
}

/**
 * Similar to `typeof`, but distinguish plain objects from `null` and arrays
 */
function getType(value) {
    if (value === null) {
        return 'null';
    }
    if (Array.isArray(value)) {
        return 'array';
    }
    return typeof value;
}

/**
 * Iterate over source and affect its sub values into destination, recursively.
 * If the source and destination can't be merged, return source.
 */
function mergeInto(destination, source, circularReferenceChecker) {
    if (circularReferenceChecker === void 0) { circularReferenceChecker = createCircularReferenceChecker(); }
    // ignore the source if it is undefined
    if (source === undefined) {
        return destination;
    }
    if (typeof source !== 'object' || source === null) {
        // primitive values - just return source
        return source;
    }
    else if (source instanceof Date) {
        return new Date(source.getTime());
    }
    else if (source instanceof RegExp) {
        var flags = source.flags ||
            // old browsers compatibility
            [
                source.global ? 'g' : '',
                source.ignoreCase ? 'i' : '',
                source.multiline ? 'm' : '',
                source.sticky ? 'y' : '',
                source.unicode ? 'u' : '',
            ].join('');
        return new RegExp(source.source, flags);
    }
    if (circularReferenceChecker.hasAlreadyBeenSeen(source)) {
        // remove circular references
        return undefined;
    }
    else if (Array.isArray(source)) {
        var merged_1 = Array.isArray(destination) ? destination : [];
        for (var i = 0; i < source.length; ++i) {
            merged_1[i] = mergeInto(merged_1[i], source[i], circularReferenceChecker);
        }
        return merged_1;
    }
    var merged = getType(destination) === 'object' ? destination : {};
    for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
            merged[key] = mergeInto(merged[key], source[key], circularReferenceChecker);
        }
    }
    return merged;
}
/**
 * A simplistic implementation of a deep clone algorithm.
 * Caveats:
 * - It doesn't maintain prototype chains - don't use with instances of custom classes.
 * - It doesn't handle Map and Set
 */
function deepClone(value) {
    return mergeInto(undefined, value);
}
function combine() {
    var sources = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        sources[_i] = arguments[_i];
    }
    var destination;
    for (var _a = 0, sources_1 = sources; _a < sources_1.length; _a++) {
        var source = sources_1[_a];
        // Ignore any undefined or null sources.
        if (source === undefined || source === null) {
            continue;
        }
        destination = mergeInto(destination, source);
    }
    return destination;
}
function createCircularReferenceChecker() {
    if (typeof WeakSet !== 'undefined') {
        var set_1 = new WeakSet();
        return {
            hasAlreadyBeenSeen: function (value) {
                var has = set_1.has(value);
                if (!has) {
                    set_1.add(value);
                }
                return has;
            },
        };
    }
    var array = [];
    return {
        hasAlreadyBeenSeen: function (value) {
            var has = array.indexOf(value) >= 0;
            if (!has) {
                array.push(value);
            }
            return has;
        },
    };
}

function getConnectivity() {
    var _a;
    var navigator = window.navigator;
    return {
        status: navigator.onLine ? 'connected' : 'not_connected',
        interfaces: navigator.connection && navigator.connection.type ? [navigator.connection.type] : undefined,
        effective_type: (_a = navigator.connection) === null || _a === void 0 ? void 0 : _a.effectiveType,
    };
}

function removeDuplicates(array) {
    var set = new Set();
    array.forEach(function (item) { return set.add(item); });
    return arrayFrom(set);
}
function removeItem(array, item) {
    var index = array.indexOf(item);
    if (index >= 0) {
        array.splice(index, 1);
    }
}

var BUFFER_LIMIT = 500;
var BoundedBuffer = /** @class */ (function () {
    function BoundedBuffer() {
        this.buffer = [];
    }
    BoundedBuffer.prototype.add = function (callback) {
        var length = this.buffer.push(callback);
        if (length > BUFFER_LIMIT) {
            this.buffer.splice(0, 1);
        }
    };
    BoundedBuffer.prototype.remove = function (callback) {
        removeItem(this.buffer, callback);
    };
    BoundedBuffer.prototype.drain = function (arg) {
        this.buffer.forEach(function (callback) { return callback(arg); });
        this.buffer.length = 0;
    };
    return BoundedBuffer;
}());

var TelemetryType = {
    log: 'log',
    configuration: 'configuration',
    usage: 'usage',
};

var ALLOWED_FRAME_URLS = [
    'https://www.datadoghq-browser-agent.com',
    'https://www.datad0g-browser-agent.com',
    'https://d3uc069fcn7uxw.cloudfront.net',
    'https://d20xtzwzcl0ceb.cloudfront.net',
    'http://localhost',
    '<anonymous>',
];
var TELEMETRY_EXCLUDED_SITES = [INTAKE_SITE_US1_FED];
// eslint-disable-next-line local-rules/disallow-side-effects
var preStartTelemetryBuffer = new BoundedBuffer();
var onRawTelemetryEventCollected = function (event) {
    preStartTelemetryBuffer.add(function () { return onRawTelemetryEventCollected(event); });
};
function startTelemetry(telemetryService, configuration) {
    var _a;
    var contextProvider;
    var observable = new Observable();
    var alreadySentEvents = new Set();
    var telemetryEnabled = !includes(TELEMETRY_EXCLUDED_SITES, configuration.site) && performDraw(configuration.telemetrySampleRate);
    var telemetryEnabledPerType = (_a = {},
        _a[TelemetryType.log] = telemetryEnabled,
        _a[TelemetryType.configuration] = telemetryEnabled && performDraw(configuration.telemetryConfigurationSampleRate),
        _a[TelemetryType.usage] = telemetryEnabled && performDraw(configuration.telemetryUsageSampleRate),
        _a);
    var runtimeEnvInfo = getRuntimeEnvInfo();
    onRawTelemetryEventCollected = function (rawEvent) {
        var stringifiedEvent = jsonStringify(rawEvent);
        if (telemetryEnabledPerType[rawEvent.type] &&
            alreadySentEvents.size < configuration.maxTelemetryEventsPerPage &&
            !alreadySentEvents.has(stringifiedEvent)) {
            var event_1 = toTelemetryEvent(telemetryService, rawEvent, runtimeEnvInfo);
            observable.notify(event_1);
            sendToExtension('telemetry', event_1);
            alreadySentEvents.add(stringifiedEvent);
        }
    };
    startMonitorErrorCollection(addTelemetryError);
    function toTelemetryEvent(telemetryService, event, runtimeEnvInfo) {
        return combine({
            type: 'telemetry',
            date: timeStampNow(),
            service: telemetryService,
            version: "5.19.0",
            source: 'browser',
            _dd: {
                format_version: 2,
            },
            telemetry: combine(event, {
                runtime_env: runtimeEnvInfo,
                connectivity: getConnectivity(),
            }),
            experimental_features: arrayFrom(getExperimentalFeatures()),
        }, contextProvider !== undefined ? contextProvider() : {});
    }
    return {
        setContextProvider: function (provider) {
            contextProvider = provider;
        },
        observable: observable,
        enabled: telemetryEnabled,
    };
}
function getRuntimeEnvInfo() {
    return {
        is_local_file: window.location.protocol === 'file:',
        is_worker: 'WorkerGlobalScope' in self,
    };
}
// need to be called after telemetry context is provided and observers are registered
function drainPreStartTelemetry() {
    preStartTelemetryBuffer.drain();
}
/**
 * Avoid mixing telemetry events from different data centers
 * but keep replicating staging events for reliability
 */
function isTelemetryReplicationAllowed(configuration) {
    return configuration.site === INTAKE_SITE_STAGING;
}
function addTelemetryDebug(message, context) {
    displayIfDebugEnabled(ConsoleApiName.debug, message, context);
    onRawTelemetryEventCollected(assign({
        type: TelemetryType.log,
        message: message,
        status: "debug" /* StatusType.debug */,
    }, context));
}
function addTelemetryError(e, context) {
    onRawTelemetryEventCollected(assign({
        type: TelemetryType.log,
        status: "error" /* StatusType.error */,
    }, formatError(e), context));
}
function addTelemetryConfiguration(configuration) {
    onRawTelemetryEventCollected({
        type: TelemetryType.configuration,
        configuration: configuration,
    });
}
function addTelemetryUsage(usage) {
    onRawTelemetryEventCollected({
        type: TelemetryType.usage,
        usage: usage,
    });
}
function formatError(e) {
    if (e instanceof Error) {
        var stackTrace = computeStackTrace(e);
        return {
            error: {
                kind: stackTrace.name,
                stack: toStackTraceString(scrubCustomerFrames(stackTrace)),
            },
            message: stackTrace.message,
        };
    }
    return {
        error: {
            stack: NO_ERROR_STACK_PRESENT_MESSAGE,
        },
        message: "".concat("Uncaught" /* NonErrorPrefix.UNCAUGHT */, " ").concat(jsonStringify(e)),
    };
}
function scrubCustomerFrames(stackTrace) {
    stackTrace.stack = stackTrace.stack.filter(function (frame) { return !frame.url || ALLOWED_FRAME_URLS.some(function (allowedFrameUrl) { return startsWith(frame.url, allowedFrameUrl); }); });
    return stackTrace;
}

var END_OF_TIMES = Infinity;
var CLEAR_OLD_VALUES_INTERVAL = ONE_MINUTE;
/**
 * Store and keep track of values spans. This whole class assumes that values are added in
 * chronological order (i.e. all entries have an increasing start time).
 */
var ValueHistory = /** @class */ (function () {
    function ValueHistory(expireDelay, maxEntries) {
        var _this = this;
        this.expireDelay = expireDelay;
        this.maxEntries = maxEntries;
        this.entries = [];
        this.clearOldValuesInterval = setInterval(function () { return _this.clearOldValues(); }, CLEAR_OLD_VALUES_INTERVAL);
    }
    /**
     * Add a value to the history associated with a start time. Returns a reference to this newly
     * added entry that can be removed or closed.
     */
    ValueHistory.prototype.add = function (value, startTime) {
        var _this = this;
        var entry = {
            value: value,
            startTime: startTime,
            endTime: END_OF_TIMES,
            remove: function () {
                removeItem(_this.entries, entry);
            },
            close: function (endTime) {
                entry.endTime = endTime;
            },
        };
        if (this.maxEntries && this.entries.length >= this.maxEntries) {
            this.entries.pop();
        }
        this.entries.unshift(entry);
        return entry;
    };
    /**
     * Return the latest value that was active during `startTime`, or the currently active value
     * if no `startTime` is provided. This method assumes that entries are not overlapping.
     *
     * If `option.returnInactive` is true, returns the value at `startTime` (active or not).
     */
    ValueHistory.prototype.find = function (startTime, options) {
        if (startTime === void 0) { startTime = END_OF_TIMES; }
        if (options === void 0) { options = { returnInactive: false }; }
        for (var _i = 0, _a = this.entries; _i < _a.length; _i++) {
            var entry = _a[_i];
            if (entry.startTime <= startTime) {
                if (options.returnInactive || startTime <= entry.endTime) {
                    return entry.value;
                }
                break;
            }
        }
    };
    /**
     * Helper function to close the currently active value, if any. This method assumes that entries
     * are not overlapping.
     */
    ValueHistory.prototype.closeActive = function (endTime) {
        var latestEntry = this.entries[0];
        if (latestEntry && latestEntry.endTime === END_OF_TIMES) {
            latestEntry.close(endTime);
        }
    };
    /**
     * Return all values with an active period overlapping with the duration,
     * or all values that were active during `startTime` if no duration is provided,
     * or all currently active values if no `startTime` is provided.
     */
    ValueHistory.prototype.findAll = function (startTime, duration) {
        if (startTime === void 0) { startTime = END_OF_TIMES; }
        if (duration === void 0) { duration = 0; }
        var endTime = addDuration(startTime, duration);
        return this.entries
            .filter(function (entry) { return entry.startTime <= endTime && startTime <= entry.endTime; })
            .map(function (entry) { return entry.value; });
    };
    /**
     * Remove all entries from this collection.
     */
    ValueHistory.prototype.reset = function () {
        this.entries = [];
    };
    /**
     * Stop internal garbage collection of past entries.
     */
    ValueHistory.prototype.stop = function () {
        clearInterval(this.clearOldValuesInterval);
    };
    ValueHistory.prototype.clearOldValues = function () {
        var oldTimeThreshold = relativeNow() - this.expireDelay;
        while (this.entries.length > 0 && this.entries[this.entries.length - 1].endTime < oldTimeThreshold) {
            this.entries.pop();
        }
    };
    return ValueHistory;
}());

var VISIBILITY_CHECK_DELAY = ONE_MINUTE;
var SESSION_CONTEXT_TIMEOUT_DELAY = SESSION_TIME_OUT_DELAY;
function startSessionManager(configuration, productKey, computeSessionState, trackingConsentState) {
    var renewObservable = new Observable();
    var expireObservable = new Observable();
    // TODO - Improve configuration type and remove assertion
    var sessionStore = startSessionStore(configuration.sessionStoreStrategyType, productKey, computeSessionState);
    var sessionContextHistory = new ValueHistory(SESSION_CONTEXT_TIMEOUT_DELAY);
    sessionStore.renewObservable.subscribe(function () {
        sessionContextHistory.add(buildSessionContext(), relativeNow());
        renewObservable.notify();
    });
    sessionStore.expireObservable.subscribe(function () {
        expireObservable.notify();
        sessionContextHistory.closeActive(relativeNow());
    });
    // We expand/renew session unconditionally as tracking consent is always granted when the session
    // manager is started.
    sessionStore.expandOrRenewSession();
    sessionContextHistory.add(buildSessionContext(), clocksOrigin().relative);
    trackingConsentState.observable.subscribe(function () {
        if (trackingConsentState.isGranted()) {
            sessionStore.expandOrRenewSession();
        }
        else {
            sessionStore.expire();
        }
    });
    trackActivity(configuration, function () {
        if (trackingConsentState.isGranted()) {
            sessionStore.expandOrRenewSession();
        }
    });
    trackVisibility(configuration, function () { return sessionStore.expandSession(); });
    trackResume(configuration, function () { return sessionStore.restartSession(); });
    function buildSessionContext() {
        return {
            id: sessionStore.getSession().id,
            trackingType: sessionStore.getSession()[productKey],
        };
    }
    return {
        findSession: function (startTime, options) { return sessionContextHistory.find(startTime, options); },
        renewObservable: renewObservable,
        expireObservable: expireObservable,
        expire: sessionStore.expire,
    };
}
function trackActivity(configuration, expandOrRenewSession) {
    addEventListeners(configuration, window, [DOM_EVENT.CLICK, DOM_EVENT.TOUCH_START, DOM_EVENT.KEY_DOWN, DOM_EVENT.SCROLL], expandOrRenewSession, { capture: true, passive: true }).stop;
}
function trackVisibility(configuration, expandSession) {
    var expandSessionWhenVisible = function () {
        if (document.visibilityState === 'visible') {
            expandSession();
        }
    };
    addEventListener(configuration, document, DOM_EVENT.VISIBILITY_CHANGE, expandSessionWhenVisible).stop;
    setInterval(expandSessionWhenVisible, VISIBILITY_CHECK_DELAY);
}
function trackResume(configuration, cb) {
    addEventListener(configuration, window, DOM_EVENT.RESUME, cb, { capture: true }).stop;
}

function isServerError(status) {
    return status >= 500;
}
function tryToClone(response) {
    try {
        return response.clone();
    }
    catch (e) {
        // clone can throw if the response has already been used by another instrumentation or is disturbed
        return;
    }
}

var MAX_ONGOING_BYTES_COUNT = 80 * ONE_KIBI_BYTE;
var MAX_ONGOING_REQUESTS = 32;
var MAX_QUEUE_BYTES_COUNT = 3 * ONE_MEBI_BYTE;
var MAX_BACKOFF_TIME = ONE_MINUTE;
var INITIAL_BACKOFF_TIME = ONE_SECOND;
function sendWithRetryStrategy(payload, state, sendStrategy, trackType, reportError) {
    if (state.transportStatus === 0 /* TransportStatus.UP */ &&
        state.queuedPayloads.size() === 0 &&
        state.bandwidthMonitor.canHandle(payload)) {
        send(payload, state, sendStrategy, {
            onSuccess: function () { return retryQueuedPayloads(0 /* RetryReason.AFTER_SUCCESS */, state, sendStrategy, trackType, reportError); },
            onFailure: function () {
                state.queuedPayloads.enqueue(payload);
                scheduleRetry(state, sendStrategy, trackType, reportError);
            },
        });
    }
    else {
        state.queuedPayloads.enqueue(payload);
    }
}
function scheduleRetry(state, sendStrategy, trackType, reportError) {
    if (state.transportStatus !== 2 /* TransportStatus.DOWN */) {
        return;
    }
    setTimeout(function () {
        var payload = state.queuedPayloads.first();
        send(payload, state, sendStrategy, {
            onSuccess: function () {
                state.queuedPayloads.dequeue();
                state.currentBackoffTime = INITIAL_BACKOFF_TIME;
                retryQueuedPayloads(1 /* RetryReason.AFTER_RESUME */, state, sendStrategy, trackType, reportError);
            },
            onFailure: function () {
                state.currentBackoffTime = Math.min(MAX_BACKOFF_TIME, state.currentBackoffTime * 2);
                scheduleRetry(state, sendStrategy, trackType, reportError);
            },
        });
    }, state.currentBackoffTime);
}
function send(payload, state, sendStrategy, _a) {
    var onSuccess = _a.onSuccess, onFailure = _a.onFailure;
    state.bandwidthMonitor.add(payload);
    sendStrategy(payload, function (response) {
        state.bandwidthMonitor.remove(payload);
        if (!shouldRetryRequest(response)) {
            state.transportStatus = 0 /* TransportStatus.UP */;
            onSuccess();
        }
        else {
            // do not consider transport down if another ongoing request could succeed
            state.transportStatus =
                state.bandwidthMonitor.ongoingRequestCount > 0 ? 1 /* TransportStatus.FAILURE_DETECTED */ : 2 /* TransportStatus.DOWN */;
            payload.retry = {
                count: payload.retry ? payload.retry.count + 1 : 1,
                lastFailureStatus: response.status,
            };
            onFailure();
        }
    });
}
function retryQueuedPayloads(reason, state, sendStrategy, trackType, reportError) {
    if (reason === 0 /* RetryReason.AFTER_SUCCESS */ && state.queuedPayloads.isFull() && !state.queueFullReported) {
        reportError({
            message: "Reached max ".concat(trackType, " events size queued for upload: ").concat(MAX_QUEUE_BYTES_COUNT / ONE_MEBI_BYTE, "MiB"),
            source: ErrorSource.AGENT,
            startClocks: clocksNow(),
        });
        state.queueFullReported = true;
    }
    var previousQueue = state.queuedPayloads;
    state.queuedPayloads = newPayloadQueue();
    while (previousQueue.size() > 0) {
        sendWithRetryStrategy(previousQueue.dequeue(), state, sendStrategy, trackType, reportError);
    }
}
function shouldRetryRequest(response) {
    return (response.type !== 'opaque' &&
        ((response.status === 0 && !navigator.onLine) ||
            response.status === 408 ||
            response.status === 429 ||
            isServerError(response.status)));
}
function newRetryState() {
    return {
        transportStatus: 0 /* TransportStatus.UP */,
        currentBackoffTime: INITIAL_BACKOFF_TIME,
        bandwidthMonitor: newBandwidthMonitor(),
        queuedPayloads: newPayloadQueue(),
        queueFullReported: false,
    };
}
function newPayloadQueue() {
    var queue = [];
    return {
        bytesCount: 0,
        enqueue: function (payload) {
            if (this.isFull()) {
                return;
            }
            queue.push(payload);
            this.bytesCount += payload.bytesCount;
        },
        first: function () {
            return queue[0];
        },
        dequeue: function () {
            var payload = queue.shift();
            if (payload) {
                this.bytesCount -= payload.bytesCount;
            }
            return payload;
        },
        size: function () {
            return queue.length;
        },
        isFull: function () {
            return this.bytesCount >= MAX_QUEUE_BYTES_COUNT;
        },
    };
}
function newBandwidthMonitor() {
    return {
        ongoingRequestCount: 0,
        ongoingByteCount: 0,
        canHandle: function (payload) {
            return (this.ongoingRequestCount === 0 ||
                (this.ongoingByteCount + payload.bytesCount <= MAX_ONGOING_BYTES_COUNT &&
                    this.ongoingRequestCount < MAX_ONGOING_REQUESTS));
        },
        add: function (payload) {
            this.ongoingRequestCount += 1;
            this.ongoingByteCount += payload.bytesCount;
        },
        remove: function (payload) {
            this.ongoingRequestCount -= 1;
            this.ongoingByteCount -= payload.bytesCount;
        },
    };
}

function createHttpRequest(configuration, endpointBuilder, bytesLimit, reportError) {
    var retryState = newRetryState();
    var sendStrategyForRetry = function (payload, onResponse) {
        return fetchKeepAliveStrategy(configuration, endpointBuilder, bytesLimit, payload, onResponse);
    };
    return {
        send: function (payload) {
            sendWithRetryStrategy(payload, retryState, sendStrategyForRetry, endpointBuilder.trackType, reportError);
        },
        /**
         * Since fetch keepalive behaves like regular fetch on Firefox,
         * keep using sendBeaconStrategy on exit
         */
        sendOnExit: function (payload) {
            sendBeaconStrategy(configuration, endpointBuilder, bytesLimit, payload);
        },
    };
}
function sendBeaconStrategy(configuration, endpointBuilder, bytesLimit, payload) {
    var canUseBeacon = !!navigator.sendBeacon && payload.bytesCount < bytesLimit;
    if (canUseBeacon) {
        try {
            var beaconUrl = endpointBuilder.build('beacon', payload);
            var isQueued = navigator.sendBeacon(beaconUrl, payload.data);
            if (isQueued) {
                return;
            }
        }
        catch (e) {
            reportBeaconError(e);
        }
    }
    var xhrUrl = endpointBuilder.build('xhr', payload);
    sendXHR(configuration, xhrUrl, payload.data);
}
var hasReportedBeaconError = false;
function reportBeaconError(e) {
    if (!hasReportedBeaconError) {
        hasReportedBeaconError = true;
        addTelemetryError(e);
    }
}
function fetchKeepAliveStrategy(configuration, endpointBuilder, bytesLimit, payload, onResponse) {
    var canUseKeepAlive = isKeepAliveSupported() && payload.bytesCount < bytesLimit;
    if (canUseKeepAlive) {
        var fetchUrl = endpointBuilder.build('fetch', payload);
        fetch(fetchUrl, { method: 'POST', body: payload.data, keepalive: true, mode: 'cors' }).then(monitor(function (response) { return onResponse === null || onResponse === void 0 ? void 0 : onResponse({ status: response.status, type: response.type }); }), monitor(function () {
            var xhrUrl = endpointBuilder.build('xhr', payload);
            // failed to queue the request
            sendXHR(configuration, xhrUrl, payload.data, onResponse);
        }));
    }
    else {
        var xhrUrl = endpointBuilder.build('xhr', payload);
        sendXHR(configuration, xhrUrl, payload.data, onResponse);
    }
}
function isKeepAliveSupported() {
    // Request can throw, cf https://developer.mozilla.org/en-US/docs/Web/API/Request/Request#errors
    try {
        return window.Request && 'keepalive' in new Request('http://a');
    }
    catch (_a) {
        return false;
    }
}
function sendXHR(configuration, url, data, onResponse) {
    var request = new XMLHttpRequest();
    request.open('POST', url, true);
    if (data instanceof Blob) {
        // When using a Blob instance, IE does not use its 'type' to define the 'Content-Type' header
        // automatically, so the intake request ends up being rejected with an HTTP status 415
        // Defining the header manually fixes this issue.
        request.setRequestHeader('Content-Type', data.type);
    }
    addEventListener(configuration, request, 'loadend', function () {
        onResponse === null || onResponse === void 0 ? void 0 : onResponse({ status: request.status });
    }, {
        // prevent multiple onResponse callbacks
        // if the xhr instance is reused by a third party
        once: true,
    });
    request.send(data);
}

function getEventBridge() {
    var eventBridgeGlobal = getEventBridgeGlobal();
    if (!eventBridgeGlobal) {
        return;
    }
    return {
        getCapabilities: function () {
            var _a;
            return JSON.parse(((_a = eventBridgeGlobal.getCapabilities) === null || _a === void 0 ? void 0 : _a.call(eventBridgeGlobal)) || '[]');
        },
        getPrivacyLevel: function () {
            var _a;
            return (_a = eventBridgeGlobal.getPrivacyLevel) === null || _a === void 0 ? void 0 : _a.call(eventBridgeGlobal);
        },
        getAllowedWebViewHosts: function () {
            return JSON.parse(eventBridgeGlobal.getAllowedWebViewHosts());
        },
        send: function (eventType, event, viewId) {
            var view = viewId ? { id: viewId } : undefined;
            eventBridgeGlobal.send(JSON.stringify({ eventType: eventType, event: event, view: view }));
        },
    };
}
function canUseEventBridge(currentHost) {
    var _a;
    if (currentHost === void 0) { currentHost = (_a = getGlobalObject().location) === null || _a === void 0 ? void 0 : _a.hostname; }
    var bridge = getEventBridge();
    return (!!bridge &&
        bridge
            .getAllowedWebViewHosts()
            .some(function (allowedHost) { return currentHost === allowedHost || endsWith(currentHost, ".".concat(allowedHost)); }));
}
function getEventBridgeGlobal() {
    return getGlobalObject().DatadogEventBridge;
}

var PageExitReason = {
    HIDDEN: 'visibility_hidden',
    UNLOADING: 'before_unload',
    PAGEHIDE: 'page_hide',
    FROZEN: 'page_frozen',
};
function createPageExitObservable(configuration) {
    return new Observable(function (observable) {
        var stopListeners = addEventListeners(configuration, window, [DOM_EVENT.VISIBILITY_CHANGE, DOM_EVENT.FREEZE], function (event) {
            if (event.type === DOM_EVENT.VISIBILITY_CHANGE && document.visibilityState === 'hidden') {
                /**
                 * Only event that guarantee to fire on mobile devices when the page transitions to background state
                 * (e.g. when user switches to a different application, goes to homescreen, etc), or is being unloaded.
                 */
                observable.notify({ reason: PageExitReason.HIDDEN });
            }
            else if (event.type === DOM_EVENT.FREEZE) {
                /**
                 * After transitioning in background a tab can be freezed to preserve resources. (cf: https://developer.chrome.com/blog/page-lifecycle-api)
                 * Allow to collect events happening between hidden and frozen state.
                 */
                observable.notify({ reason: PageExitReason.FROZEN });
            }
        }, { capture: true }).stop;
        var stopBeforeUnloadListener = addEventListener(configuration, window, DOM_EVENT.BEFORE_UNLOAD, function () {
            observable.notify({ reason: PageExitReason.UNLOADING });
        }).stop;
        return function () {
            stopListeners();
            stopBeforeUnloadListener();
        };
    });
}
function isPageExitReason(reason) {
    return includes(objectValues(PageExitReason), reason);
}

var Batch = /** @class */ (function () {
    function Batch(encoder, request, flushController, messageBytesLimit) {
        var _this = this;
        this.encoder = encoder;
        this.request = request;
        this.flushController = flushController;
        this.messageBytesLimit = messageBytesLimit;
        this.upsertBuffer = {};
        this.flushSubscription = this.flushController.flushObservable.subscribe(function (event) { return _this.flush(event); });
    }
    Batch.prototype.add = function (message) {
        this.addOrUpdate(message);
    };
    Batch.prototype.upsert = function (message, key) {
        this.addOrUpdate(message, key);
    };
    Batch.prototype.stop = function () {
        this.flushSubscription.unsubscribe();
    };
    Batch.prototype.flush = function (event) {
        var upsertMessages = objectValues(this.upsertBuffer).join('\n');
        this.upsertBuffer = {};
        var isPageExit = isPageExitReason(event.reason);
        var send = isPageExit ? this.request.sendOnExit : this.request.send;
        if (isPageExit &&
            // Note: checking that the encoder is async is not strictly needed, but it's an optimization:
            // if the encoder is async we need to send two requests in some cases (one for encoded data
            // and the other for non-encoded data). But if it's not async, we don't have to worry about
            // it and always send a single request.
            this.encoder.isAsync) {
            var encoderResult = this.encoder.finishSync();
            // Send encoded messages
            if (encoderResult.outputBytesCount) {
                send(formatPayloadFromEncoder(encoderResult));
            }
            // Send messages that are not yet encoded at this point
            var pendingMessages = [encoderResult.pendingData, upsertMessages].filter(Boolean).join('\n');
            if (pendingMessages) {
                send({
                    data: pendingMessages,
                    bytesCount: computeBytesCount(pendingMessages),
                });
            }
        }
        else {
            if (upsertMessages) {
                this.encoder.write(this.encoder.isEmpty ? upsertMessages : "\n".concat(upsertMessages));
            }
            this.encoder.finish(function (encoderResult) {
                send(formatPayloadFromEncoder(encoderResult));
            });
        }
    };
    Batch.prototype.addOrUpdate = function (message, key) {
        var serializedMessage = jsonStringify(message);
        var estimatedMessageBytesCount = this.encoder.estimateEncodedBytesCount(serializedMessage);
        if (estimatedMessageBytesCount >= this.messageBytesLimit) {
            display.warn("Discarded a message whose size was bigger than the maximum allowed size ".concat(this.messageBytesLimit, "KB."));
            return;
        }
        if (this.hasMessageFor(key)) {
            this.remove(key);
        }
        this.push(serializedMessage, estimatedMessageBytesCount, key);
    };
    Batch.prototype.push = function (serializedMessage, estimatedMessageBytesCount, key) {
        var _this = this;
        this.flushController.notifyBeforeAddMessage(estimatedMessageBytesCount);
        if (key !== undefined) {
            this.upsertBuffer[key] = serializedMessage;
            this.flushController.notifyAfterAddMessage();
        }
        else {
            this.encoder.write(this.encoder.isEmpty ? serializedMessage : "\n".concat(serializedMessage), function (realMessageBytesCount) {
                _this.flushController.notifyAfterAddMessage(realMessageBytesCount - estimatedMessageBytesCount);
            });
        }
    };
    Batch.prototype.remove = function (key) {
        var removedMessage = this.upsertBuffer[key];
        delete this.upsertBuffer[key];
        var messageBytesCount = this.encoder.estimateEncodedBytesCount(removedMessage);
        this.flushController.notifyAfterRemoveMessage(messageBytesCount);
    };
    Batch.prototype.hasMessageFor = function (key) {
        return key !== undefined && this.upsertBuffer[key] !== undefined;
    };
    return Batch;
}());
function formatPayloadFromEncoder(encoderResult) {
    var data;
    if (typeof encoderResult.output === 'string') {
        data = encoderResult.output;
    }
    else {
        data = new Blob([encoderResult.output], {
            // This will set the 'Content-Type: text/plain' header. Reasoning:
            // * The intake rejects the request if there is no content type.
            // * The browser will issue CORS preflight requests if we set it to 'application/json', which
            // could induce higher intake load (and maybe has other impacts).
            // * Also it's not quite JSON, since we are concatenating multiple JSON objects separated by
            // new lines.
            type: 'text/plain',
        });
    }
    return {
        data: data,
        bytesCount: encoderResult.outputBytesCount,
        encoding: encoderResult.encoding,
    };
}

/**
 * Returns a "flush controller", responsible of notifying when flushing a pool of pending data needs
 * to happen. The implementation is designed to support both synchronous and asynchronous usages,
 * but relies on invariants described in each method documentation to keep a coherent state.
 */
function createFlushController(_a) {
    var messagesLimit = _a.messagesLimit, bytesLimit = _a.bytesLimit, durationLimit = _a.durationLimit, pageExitObservable = _a.pageExitObservable, sessionExpireObservable = _a.sessionExpireObservable;
    var pageExitSubscription = pageExitObservable.subscribe(function (event) { return flush(event.reason); });
    var sessionExpireSubscription = sessionExpireObservable.subscribe(function () { return flush('session_expire'); });
    var flushObservable = new Observable(function () { return function () {
        pageExitSubscription.unsubscribe();
        sessionExpireSubscription.unsubscribe();
    }; });
    var currentBytesCount = 0;
    var currentMessagesCount = 0;
    function flush(flushReason) {
        if (currentMessagesCount === 0) {
            return;
        }
        var messagesCount = currentMessagesCount;
        var bytesCount = currentBytesCount;
        currentMessagesCount = 0;
        currentBytesCount = 0;
        cancelDurationLimitTimeout();
        flushObservable.notify({
            reason: flushReason,
            messagesCount: messagesCount,
            bytesCount: bytesCount,
        });
    }
    var durationLimitTimeoutId;
    function scheduleDurationLimitTimeout() {
        if (durationLimitTimeoutId === undefined) {
            durationLimitTimeoutId = setTimeout(function () {
                flush('duration_limit');
            }, durationLimit);
        }
    }
    function cancelDurationLimitTimeout() {
        clearTimeout(durationLimitTimeoutId);
        durationLimitTimeoutId = undefined;
    }
    return {
        flushObservable: flushObservable,
        get messagesCount() {
            return currentMessagesCount;
        },
        /**
         * Notifies that a message will be added to a pool of pending messages waiting to be flushed.
         *
         * This function needs to be called synchronously, right before adding the message, so no flush
         * event can happen after `notifyBeforeAddMessage` and before adding the message.
         *
         * @param estimatedMessageBytesCount: an estimation of the message bytes count once it is
         * actually added.
         */
        notifyBeforeAddMessage: function (estimatedMessageBytesCount) {
            if (currentBytesCount + estimatedMessageBytesCount >= bytesLimit) {
                flush('bytes_limit');
            }
            // Consider the message to be added now rather than in `notifyAfterAddMessage`, because if no
            // message was added yet and `notifyAfterAddMessage` is called asynchronously, we still want
            // to notify when a flush is needed (for example on page exit).
            currentMessagesCount += 1;
            currentBytesCount += estimatedMessageBytesCount;
            scheduleDurationLimitTimeout();
        },
        /**
         * Notifies that a message *was* added to a pool of pending messages waiting to be flushed.
         *
         * This function can be called asynchronously after the message was added, but in this case it
         * should not be called if a flush event occurred in between.
         *
         * @param messageBytesCountDiff: the difference between the estimated message bytes count and
         * its actual bytes count once added to the pool.
         */
        notifyAfterAddMessage: function (messageBytesCountDiff) {
            if (messageBytesCountDiff === void 0) { messageBytesCountDiff = 0; }
            currentBytesCount += messageBytesCountDiff;
            if (currentMessagesCount >= messagesLimit) {
                flush('messages_limit');
            }
            else if (currentBytesCount >= bytesLimit) {
                flush('bytes_limit');
            }
        },
        /**
         * Notifies that a message was removed from a pool of pending messages waiting to be flushed.
         *
         * This function needs to be called synchronously, right after removing the message, so no flush
         * event can happen after removing the message and before `notifyAfterRemoveMessage`.
         *
         * @param messageBytesCount: the message bytes count that was added to the pool. Should
         * correspond to the sum of bytes counts passed to `notifyBeforeAddMessage` and
         * `notifyAfterAddMessage`.
         */
        notifyAfterRemoveMessage: function (messageBytesCount) {
            currentBytesCount -= messageBytesCount;
            currentMessagesCount -= 1;
            if (currentMessagesCount === 0) {
                cancelDurationLimitTimeout();
            }
        },
    };
}

function startBatchWithReplica(configuration, primary, replica, reportError, pageExitObservable, sessionExpireObservable) {
    var primaryBatch = createBatch(configuration, primary);
    var replicaBatch = replica && createBatch(configuration, replica);
    function createBatch(configuration, _a) {
        var endpoint = _a.endpoint, encoder = _a.encoder;
        return new Batch(encoder, createHttpRequest(configuration, endpoint, configuration.batchBytesLimit, reportError), createFlushController({
            messagesLimit: configuration.batchMessagesLimit,
            bytesLimit: configuration.batchBytesLimit,
            durationLimit: configuration.flushTimeout,
            pageExitObservable: pageExitObservable,
            sessionExpireObservable: sessionExpireObservable,
        }), configuration.messageBytesLimit);
    }
    return {
        flushObservable: primaryBatch.flushController.flushObservable,
        add: function (message, replicated) {
            if (replicated === void 0) { replicated = true; }
            primaryBatch.add(message);
            if (replicaBatch && replicated) {
                replicaBatch.add(replica.transformMessage ? replica.transformMessage(message) : message);
            }
        },
        upsert: function (message, key) {
            primaryBatch.upsert(message, key);
            if (replicaBatch) {
                replicaBatch.upsert(replica.transformMessage ? replica.transformMessage(message) : message, key);
            }
        },
        stop: function () {
            primaryBatch.stop();
            replicaBatch === null || replicaBatch === void 0 ? void 0 : replicaBatch.stop();
        },
    };
}

function createIdentityEncoder() {
    var output = '';
    var outputBytesCount = 0;
    return {
        isAsync: false,
        get isEmpty() {
            return !output;
        },
        write: function (data, callback) {
            var additionalEncodedBytesCount = computeBytesCount(data);
            outputBytesCount += additionalEncodedBytesCount;
            output += data;
            if (callback) {
                callback(additionalEncodedBytesCount);
            }
        },
        finish: function (callback) {
            callback(this.finishSync());
        },
        finishSync: function () {
            var result = {
                output: output,
                outputBytesCount: outputBytesCount,
                rawBytesCount: outputBytesCount,
                pendingData: '',
            };
            output = '';
            outputBytesCount = 0;
            return result;
        },
        estimateEncodedBytesCount: function (data) {
            return data.length;
        },
    };
}

var AbstractLifeCycle = /** @class */ (function () {
    function AbstractLifeCycle() {
        this.callbacks = {};
    }
    AbstractLifeCycle.prototype.notify = function (eventType, data) {
        var eventCallbacks = this.callbacks[eventType];
        if (eventCallbacks) {
            eventCallbacks.forEach(function (callback) { return callback(data); });
        }
    };
    AbstractLifeCycle.prototype.subscribe = function (eventType, callback) {
        var _this = this;
        if (!this.callbacks[eventType]) {
            this.callbacks[eventType] = [];
        }
        this.callbacks[eventType].push(callback);
        return {
            unsubscribe: function () {
                _this.callbacks[eventType] = _this.callbacks[eventType].filter(function (other) { return callback !== other; });
            },
        };
    };
    return AbstractLifeCycle;
}());

function createEventRateLimiter(eventType, limit, onLimitReached) {
    var eventCount = 0;
    var allowNextEvent = false;
    return {
        isLimitReached: function () {
            if (eventCount === 0) {
                setTimeout(function () {
                    eventCount = 0;
                }, ONE_MINUTE);
            }
            eventCount += 1;
            if (eventCount <= limit || allowNextEvent) {
                allowNextEvent = false;
                return false;
            }
            if (eventCount === limit + 1) {
                allowNextEvent = true;
                try {
                    onLimitReached({
                        message: "Reached max number of ".concat(eventType, "s by minute: ").concat(limit),
                        source: ErrorSource.AGENT,
                        startClocks: clocksNow(),
                    });
                }
                finally {
                    allowNextEvent = false;
                }
            }
            return true;
        },
    };
}

var xhrObservable;
var xhrContexts = new WeakMap();
function initXhrObservable(configuration) {
    if (!xhrObservable) {
        xhrObservable = createXhrObservable(configuration);
    }
    return xhrObservable;
}
function createXhrObservable(configuration) {
    return new Observable(function (observable) {
        var stopInstrumentingStart = instrumentMethod(XMLHttpRequest.prototype, 'open', openXhr).stop;
        var stopInstrumentingSend = instrumentMethod(XMLHttpRequest.prototype, 'send', function (call) {
            sendXhr(call, configuration, observable);
        }).stop;
        var stopInstrumentingAbort = instrumentMethod(XMLHttpRequest.prototype, 'abort', abortXhr).stop;
        return function () {
            stopInstrumentingStart();
            stopInstrumentingSend();
            stopInstrumentingAbort();
        };
    });
}
function openXhr(_a) {
    var xhr = _a.target, _b = _a.parameters, method = _b[0], url = _b[1];
    xhrContexts.set(xhr, {
        state: 'open',
        method: String(method).toUpperCase(),
        url: normalizeUrl(String(url)),
    });
}
function sendXhr(_a, configuration, observable) {
    var xhr = _a.target;
    var context = xhrContexts.get(xhr);
    if (!context) {
        return;
    }
    var startContext = context;
    startContext.state = 'start';
    startContext.startClocks = clocksNow();
    startContext.isAborted = false;
    startContext.xhr = xhr;
    var hasBeenReported = false;
    var stopInstrumentingOnReadyStateChange = instrumentMethod(xhr, 'onreadystatechange', function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            // Try to report the XHR as soon as possible, because the XHR may be mutated by the
            // application during a future event. For example, Angular is calling .abort() on
            // completed requests during an onreadystatechange event, so the status becomes '0'
            // before the request is collected.
            onEnd();
        }
    }).stop;
    var onEnd = function () {
        unsubscribeLoadEndListener();
        stopInstrumentingOnReadyStateChange();
        if (hasBeenReported) {
            return;
        }
        hasBeenReported = true;
        var completeContext = context;
        completeContext.state = 'complete';
        completeContext.duration = elapsed(startContext.startClocks.timeStamp, timeStampNow());
        completeContext.status = xhr.status;
        observable.notify(shallowClone(completeContext));
    };
    var unsubscribeLoadEndListener = addEventListener(configuration, xhr, 'loadend', onEnd).stop;
    observable.notify(startContext);
}
function abortXhr(_a) {
    var xhr = _a.target;
    var context = xhrContexts.get(xhr);
    if (context) {
        context.isAborted = true;
    }
}

var fetchObservable;
function initFetchObservable() {
    if (!fetchObservable) {
        fetchObservable = createFetchObservable();
    }
    return fetchObservable;
}
function createFetchObservable() {
    return new Observable(function (observable) {
        if (!window.fetch) {
            return;
        }
        var stop = instrumentMethod(window, 'fetch', function (call) { return beforeSend(call, observable); }).stop;
        return stop;
    });
}
function beforeSend(_a, observable) {
    var parameters = _a.parameters, onPostCall = _a.onPostCall;
    var input = parameters[0], init = parameters[1];
    var methodFromParams = init && init.method;
    if (methodFromParams === undefined && input instanceof Request) {
        methodFromParams = input.method;
    }
    var method = methodFromParams !== undefined ? String(methodFromParams).toUpperCase() : 'GET';
    var url = input instanceof Request ? input.url : normalizeUrl(String(input));
    var startClocks = clocksNow();
    var context = {
        state: 'start',
        init: init,
        input: input,
        method: method,
        startClocks: startClocks,
        url: url,
    };
    observable.notify(context);
    // Those properties can be changed by observable subscribers
    parameters[0] = context.input;
    parameters[1] = context.init;
    onPostCall(function (responsePromise) { return afterSend(observable, responsePromise, context); });
}
function afterSend(observable, responsePromise, startContext) {
    var reportFetch = function (response) {
        var context = startContext;
        context.state = 'resolve';
        if ('stack' in response || response instanceof Error) {
            context.status = 0;
            context.isAborted = response instanceof DOMException && response.code === DOMException.ABORT_ERR;
            context.error = response;
        }
        else if ('status' in response) {
            context.response = response;
            context.responseType = response.type;
            context.status = response.status;
            context.isAborted = false;
        }
        observable.notify(context);
    };
    responsePromise.then(monitor(reportFetch), monitor(reportFetch));
}

var consoleObservablesByApi = {};
function initConsoleObservable(apis) {
    var consoleObservables = apis.map(function (api) {
        if (!consoleObservablesByApi[api]) {
            consoleObservablesByApi[api] = createConsoleObservable(api);
        }
        return consoleObservablesByApi[api];
    });
    return mergeObservables.apply(void 0, consoleObservables);
}
function createConsoleObservable(api) {
    return new Observable(function (observable) {
        var originalConsoleApi = globalConsole[api];
        globalConsole[api] = function () {
            var params = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                params[_i] = arguments[_i];
            }
            originalConsoleApi.apply(console, params);
            var handlingStack = createHandlingStack();
            callMonitored(function () {
                observable.notify(buildConsoleLog(params, api, handlingStack));
            });
        };
        return function () {
            globalConsole[api] = originalConsoleApi;
        };
    });
}
function buildConsoleLog(params, api, handlingStack) {
    var message = params.map(function (param) { return formatConsoleParameters(param); }).join(' ');
    var stack;
    var fingerprint;
    var causes;
    if (api === ConsoleApiName.error) {
        var firstErrorParam = find(params, function (param) { return param instanceof Error; });
        stack = firstErrorParam ? toStackTraceString(computeStackTrace(firstErrorParam)) : undefined;
        fingerprint = tryToGetFingerprint(firstErrorParam);
        causes = firstErrorParam ? flattenErrorCauses(firstErrorParam, 'console') : undefined;
    }
    return {
        api: api,
        message: message,
        stack: stack,
        handlingStack: handlingStack,
        fingerprint: fingerprint,
        causes: causes,
    };
}
function formatConsoleParameters(param) {
    if (typeof param === 'string') {
        return sanitize(param);
    }
    if (param instanceof Error) {
        return formatErrorMessage(computeStackTrace(param));
    }
    return jsonStringify(sanitize(param), undefined, 2);
}

function createContextManager(customerDataTracker) {
    var context = {};
    var changeObservable = new Observable();
    var contextManager = {
        getContext: function () { return deepClone(context); },
        setContext: function (newContext) {
            if (getType(newContext) === 'object') {
                context = sanitize(newContext);
                customerDataTracker.updateCustomerData(context);
            }
            else {
                contextManager.clearContext();
            }
            changeObservable.notify();
        },
        setContextProperty: function (key, property) {
            context[key] = sanitize(property);
            customerDataTracker.updateCustomerData(context);
            changeObservable.notify();
        },
        removeContextProperty: function (key) {
            delete context[key];
            customerDataTracker.updateCustomerData(context);
            changeObservable.notify();
        },
        clearContext: function () {
            context = {};
            customerDataTracker.resetCustomerData();
            changeObservable.notify();
        },
        changeObservable: changeObservable,
    };
    return contextManager;
}

var CONTEXT_STORE_KEY_PREFIX = '_dd_c';
var storageListeners = [];
function storeContextManager(configuration, contextManager, productKey, customerDataType) {
    var storageKey = buildStorageKey(productKey, customerDataType);
    storageListeners.push(addEventListener(configuration, window, DOM_EVENT.STORAGE, function (_a) {
        var key = _a.key;
        if (storageKey === key) {
            synchronizeWithStorage();
        }
    }));
    contextManager.changeObservable.subscribe(dumpToStorage);
    contextManager.setContext(combine(getFromStorage(), contextManager.getContext()));
    function synchronizeWithStorage() {
        contextManager.setContext(getFromStorage());
    }
    function dumpToStorage() {
        localStorage.setItem(storageKey, JSON.stringify(contextManager.getContext()));
    }
    function getFromStorage() {
        var rawContext = localStorage.getItem(storageKey);
        return rawContext !== null ? JSON.parse(rawContext) : {};
    }
}
function buildStorageKey(productKey, customerDataType) {
    return "".concat(CONTEXT_STORE_KEY_PREFIX, "_").concat(productKey, "_").concat(customerDataType);
}

// RUM and logs batch bytes limit is 16KB
// ensure that we leave room for other event attributes and maintain a decent amount of event per batch
// (3KB (customer data) + 1KB (other attributes)) * 4 (events per batch) = 16KB
var CUSTOMER_DATA_BYTES_LIMIT = 3 * ONE_KIBI_BYTE;
// We observed that the compression ratio is around 8 in general, but we also want to keep a margin
// because some data might not be compressed (ex: last view update on page exit). We chose 16KiB
// because it is also the limit of the 'batchBytesCount' that we use for RUM and Logs data, but this
// is a bit arbitrary.
var CUSTOMER_COMPRESSED_DATA_BYTES_LIMIT = 16 * ONE_KIBI_BYTE;
var BYTES_COMPUTATION_THROTTLING_DELAY = 200;
function createCustomerDataTrackerManager(compressionStatus) {
    if (compressionStatus === void 0) { compressionStatus = 2 /* CustomerDataCompressionStatus.Disabled */; }
    var customerDataTrackers = new Map();
    var alreadyWarned = false;
    function checkCustomerDataLimit(initialBytesCount) {
        if (initialBytesCount === void 0) { initialBytesCount = 0; }
        if (alreadyWarned || compressionStatus === 0 /* CustomerDataCompressionStatus.Unknown */) {
            return;
        }
        var bytesCountLimit = compressionStatus === 2 /* CustomerDataCompressionStatus.Disabled */
            ? CUSTOMER_DATA_BYTES_LIMIT
            : CUSTOMER_COMPRESSED_DATA_BYTES_LIMIT;
        var bytesCount = initialBytesCount;
        customerDataTrackers.forEach(function (tracker) {
            bytesCount += tracker.getBytesCount();
        });
        if (bytesCount > bytesCountLimit) {
            displayCustomerDataLimitReachedWarning(bytesCountLimit);
            alreadyWarned = true;
        }
    }
    return {
        /**
         * Creates a detached tracker. The manager will not store a reference to that tracker, and the
         * bytes count will be counted independently from other detached trackers.
         *
         * This is particularly useful when we don't know when the tracker will be unused, so we don't
         * leak memory (ex: when used in Logger instances).
         */
        createDetachedTracker: function () {
            var tracker = createCustomerDataTracker(function () { return checkCustomerDataLimit(tracker.getBytesCount()); });
            return tracker;
        },
        /**
         * Creates a tracker if it doesn't exist, and returns it.
         */
        getOrCreateTracker: function (type) {
            if (!customerDataTrackers.has(type)) {
                customerDataTrackers.set(type, createCustomerDataTracker(checkCustomerDataLimit));
            }
            return customerDataTrackers.get(type);
        },
        setCompressionStatus: function (newCompressionStatus) {
            if (compressionStatus === 0 /* CustomerDataCompressionStatus.Unknown */) {
                compressionStatus = newCompressionStatus;
                checkCustomerDataLimit();
            }
        },
        getCompressionStatus: function () { return compressionStatus; },
        stop: function () {
            customerDataTrackers.forEach(function (tracker) { return tracker.stop(); });
            customerDataTrackers.clear();
        },
    };
}
function createCustomerDataTracker(checkCustomerDataLimit) {
    var bytesCountCache = 0;
    // Throttle the bytes computation to minimize the impact on performance.
    // Especially useful if the user call context APIs synchronously multiple times in a row
    var _a = throttle(function (context) {
        bytesCountCache = computeBytesCount(jsonStringify(context));
        checkCustomerDataLimit();
    }, BYTES_COMPUTATION_THROTTLING_DELAY), computeBytesCountThrottled = _a.throttled, cancelComputeBytesCount = _a.cancel;
    var resetBytesCount = function () {
        cancelComputeBytesCount();
        bytesCountCache = 0;
    };
    return {
        updateCustomerData: function (context) {
            if (isEmptyObject(context)) {
                resetBytesCount();
            }
            else {
                computeBytesCountThrottled(context);
            }
        },
        resetCustomerData: resetBytesCount,
        getBytesCount: function () { return bytesCountCache; },
        stop: function () {
            cancelComputeBytesCount();
        },
    };
}
function displayCustomerDataLimitReachedWarning(bytesCountLimit) {
    display.warn("Customer data exceeds the recommended ".concat(bytesCountLimit / ONE_KIBI_BYTE, "KiB threshold. More details: https://docs.datadoghq.com/real_user_monitoring/browser/troubleshooting/#customer-data-exceeds-the-recommended-threshold-warning"));
}

/**
 * Read bytes from a ReadableStream until at least `limit` bytes have been read (or until the end of
 * the stream). The callback is invoked with the at most `limit` bytes, and indicates that the limit
 * has been exceeded if more bytes were available.
 */
function readBytesFromStream(stream, callback, options) {
    var reader = stream.getReader();
    var chunks = [];
    var readBytesCount = 0;
    readMore();
    function readMore() {
        reader.read().then(monitor(function (result) {
            if (result.done) {
                onDone();
                return;
            }
            {
                chunks.push(result.value);
            }
            readBytesCount += result.value.length;
            if (readBytesCount > options.bytesLimit) {
                onDone();
            }
            else {
                readMore();
            }
        }), monitor(function (error) { return callback(error); }));
    }
    function onDone() {
        reader.cancel().catch(
        // we don't care if cancel fails, but we still need to catch the error to avoid reporting it
        // as an unhandled rejection
        noop);
        var bytes;
        var limitExceeded;
        {
            var completeBuffer_1;
            if (chunks.length === 1) {
                // optimization: if the response is small enough to fit in a single buffer (provided by the browser), just
                // use it directly.
                completeBuffer_1 = chunks[0];
            }
            else {
                // else, we need to copy buffers into a larger buffer to concatenate them.
                completeBuffer_1 = new Uint8Array(readBytesCount);
                var offset_1 = 0;
                chunks.forEach(function (chunk) {
                    completeBuffer_1.set(chunk, offset_1);
                    offset_1 += chunk.length;
                });
            }
            bytes = completeBuffer_1.slice(0, options.bytesLimit);
            limitExceeded = completeBuffer_1.length > options.bytesLimit;
        }
        callback(undefined, bytes, limitExceeded);
    }
}

var SYNTHETICS_TEST_ID_COOKIE_NAME = 'datadog-synthetics-public-id';
var SYNTHETICS_RESULT_ID_COOKIE_NAME = 'datadog-synthetics-result-id';
var SYNTHETICS_INJECTS_RUM_COOKIE_NAME = 'datadog-synthetics-injects-rum';
function willSyntheticsInjectRum() {
    return Boolean(window._DATADOG_SYNTHETICS_INJECTS_RUM || getInitCookie(SYNTHETICS_INJECTS_RUM_COOKIE_NAME));
}
function getSyntheticsTestId() {
    var value = window._DATADOG_SYNTHETICS_PUBLIC_ID || getInitCookie(SYNTHETICS_TEST_ID_COOKIE_NAME);
    return typeof value === 'string' ? value : undefined;
}
function getSyntheticsResultId() {
    var value = window._DATADOG_SYNTHETICS_RESULT_ID || getInitCookie(SYNTHETICS_RESULT_ID_COOKIE_NAME);
    return typeof value === 'string' ? value : undefined;
}

/**
 * Clone input data and ensure known user properties (id, name, email)
 * are strings, as defined here:
 * https://docs.datadoghq.com/logs/log_configuration/attributes_naming_convention/#user-related-attributes
 */
function sanitizeUser(newUser) {
    // We shallow clone only to prevent mutation of user data.
    var user = assign({}, newUser);
    var keys = ['id', 'name', 'email'];
    keys.forEach(function (key) {
        if (key in user) {
            user[key] = String(user[key]);
        }
    });
    return user;
}
/**
 * Simple check to ensure user is valid
 */
function checkUser(newUser) {
    var isValid = getType(newUser) === 'object';
    if (!isValid) {
        display.error('Unsupported user:', newUser);
    }
    return isValid;
}

var __decorate = (globalThis && globalThis.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var StatusType = {
    debug: 'debug',
    error: 'error',
    info: 'info',
    warn: 'warn',
};
var HandlerType = {
    console: 'console',
    http: 'http',
    silent: 'silent',
};
var STATUSES = Object.keys(StatusType);
var Logger = /** @class */ (function () {
    function Logger(handleLogStrategy, customerDataTracker, name, handlerType, level, loggerContext) {
        if (handlerType === void 0) { handlerType = HandlerType.http; }
        if (level === void 0) { level = StatusType.debug; }
        if (loggerContext === void 0) { loggerContext = {}; }
        this.handleLogStrategy = handleLogStrategy;
        this.handlerType = handlerType;
        this.level = level;
        this.contextManager = createContextManager(customerDataTracker);
        this.contextManager.setContext(loggerContext);
        if (name) {
            this.contextManager.setContextProperty('logger', { name: name });
        }
    }
    Logger.prototype.log = function (message, messageContext, status, error) {
        if (status === void 0) { status = StatusType.info; }
        var errorContext;
        if (error !== undefined && error !== null) {
            var stackTrace = error instanceof Error ? computeStackTrace(error) : undefined;
            var rawError = computeRawError({
                stackTrace: stackTrace,
                originalError: error,
                nonErrorPrefix: "Provided" /* NonErrorPrefix.PROVIDED */,
                source: ErrorSource.LOGGER,
                handling: "handled" /* ErrorHandling.HANDLED */,
                startClocks: clocksNow(),
            });
            errorContext = {
                stack: rawError.stack,
                kind: rawError.type,
                message: rawError.message,
                causes: rawError.causes,
            };
        }
        var sanitizedMessageContext = sanitize(messageContext);
        var context = errorContext
            ? combine({ error: errorContext }, sanitizedMessageContext)
            : sanitizedMessageContext;
        this.handleLogStrategy({
            message: sanitize(message),
            context: context,
            status: status,
        }, this);
    };
    Logger.prototype.debug = function (message, messageContext, error) {
        this.log(message, messageContext, StatusType.debug, error);
    };
    Logger.prototype.info = function (message, messageContext, error) {
        this.log(message, messageContext, StatusType.info, error);
    };
    Logger.prototype.warn = function (message, messageContext, error) {
        this.log(message, messageContext, StatusType.warn, error);
    };
    Logger.prototype.error = function (message, messageContext, error) {
        this.log(message, messageContext, StatusType.error, error);
    };
    Logger.prototype.setContext = function (context) {
        this.contextManager.setContext(context);
    };
    Logger.prototype.getContext = function () {
        return this.contextManager.getContext();
    };
    Logger.prototype.setContextProperty = function (key, value) {
        this.contextManager.setContextProperty(key, value);
    };
    Logger.prototype.removeContextProperty = function (key) {
        this.contextManager.removeContextProperty(key);
    };
    Logger.prototype.clearContext = function () {
        this.contextManager.clearContext();
    };
    Logger.prototype.setHandler = function (handler) {
        this.handlerType = handler;
    };
    Logger.prototype.getHandler = function () {
        return this.handlerType;
    };
    Logger.prototype.setLevel = function (level) {
        this.level = level;
    };
    Logger.prototype.getLevel = function () {
        return this.level;
    };
    __decorate([
        monitored
    ], Logger.prototype, "log", null);
    return Logger;
}());

function buildCommonContext(globalContextManager, userContextManager) {
    return {
        view: {
            referrer: document.referrer,
            url: window.location.href,
        },
        context: globalContextManager.getContext(),
        user: userContextManager.getContext(),
    };
}

/**
 * arbitrary value, byte precision not needed
 */
var DEFAULT_REQUEST_ERROR_RESPONSE_LENGTH_LIMIT = 32 * ONE_KIBI_BYTE;
function validateAndBuildLogsConfiguration(initConfiguration) {
    if (initConfiguration.usePciIntake === true && initConfiguration.site && initConfiguration.site !== 'datadoghq.com') {
        display.warn('PCI compliance for Logs is only available for Datadog organizations in the US1 site. Default intake will be used.');
    }
    var baseConfiguration = validateAndBuildConfiguration(initConfiguration);
    var forwardConsoleLogs = validateAndBuildForwardOption(initConfiguration.forwardConsoleLogs, objectValues(ConsoleApiName), 'Forward Console Logs');
    var forwardReports = validateAndBuildForwardOption(initConfiguration.forwardReports, objectValues(RawReportType), 'Forward Reports');
    if (!baseConfiguration || !forwardConsoleLogs || !forwardReports) {
        return;
    }
    if (initConfiguration.forwardErrorsToLogs && !includes(forwardConsoleLogs, ConsoleApiName.error)) {
        forwardConsoleLogs.push(ConsoleApiName.error);
    }
    return assign({
        forwardErrorsToLogs: initConfiguration.forwardErrorsToLogs !== false,
        forwardConsoleLogs: forwardConsoleLogs,
        forwardReports: forwardReports,
        requestErrorResponseLengthLimit: DEFAULT_REQUEST_ERROR_RESPONSE_LENGTH_LIMIT,
        sendLogsAfterSessionExpiration: !!initConfiguration.sendLogsAfterSessionExpiration,
    }, baseConfiguration);
}
function validateAndBuildForwardOption(option, allowedValues, label) {
    if (option === undefined) {
        return [];
    }
    if (!(option === 'all' || (Array.isArray(option) && option.every(function (api) { return includes(allowedValues, api); })))) {
        display.error("".concat(label, " should be \"all\" or an array with allowed values \"").concat(allowedValues.join('", "'), "\""));
        return;
    }
    return option === 'all' ? allowedValues : removeDuplicates(option);
}
function serializeLogsConfiguration(configuration) {
    var baseSerializedInitConfiguration = serializeConfiguration(configuration);
    return assign({
        forward_errors_to_logs: configuration.forwardErrorsToLogs,
        forward_console_logs: configuration.forwardConsoleLogs,
        forward_reports: configuration.forwardReports,
        use_pci_intake: configuration.usePciIntake,
        send_logs_after_session_expiration: configuration.sendLogsAfterSessionExpiration,
    }, baseSerializedInitConfiguration);
}

function createPreStartStrategy(getCommonContext, trackingConsentState, doStartLogs) {
    var bufferApiCalls = new BoundedBuffer();
    var cachedInitConfiguration;
    var cachedConfiguration;
    var trackingConsentStateSubscription = trackingConsentState.observable.subscribe(tryStartLogs);
    function tryStartLogs() {
        if (!cachedConfiguration || !cachedInitConfiguration || !trackingConsentState.isGranted()) {
            return;
        }
        trackingConsentStateSubscription.unsubscribe();
        var startLogsResult = doStartLogs(cachedInitConfiguration, cachedConfiguration);
        bufferApiCalls.drain(startLogsResult);
    }
    return {
        init: function (initConfiguration) {
            if (!initConfiguration) {
                display.error('Missing configuration');
                return;
            }
            if (canUseEventBridge()) {
                initConfiguration = overrideInitConfigurationForBridge(initConfiguration);
            }
            // Expose the initial configuration regardless of initialization success.
            cachedInitConfiguration = initConfiguration;
            if (cachedConfiguration) {
                displayAlreadyInitializedError('DD_LOGS', initConfiguration);
                return;
            }
            var configuration = validateAndBuildLogsConfiguration(initConfiguration);
            if (!configuration) {
                return;
            }
            cachedConfiguration = configuration;
            trackingConsentState.tryToInit(configuration.trackingConsent);
            tryStartLogs();
        },
        get initConfiguration() {
            return cachedInitConfiguration;
        },
        getInternalContext: noop,
        handleLog: function (message, statusType, context, date) {
            if (context === void 0) { context = getCommonContext(); }
            if (date === void 0) { date = timeStampNow(); }
            bufferApiCalls.add(function (startLogsResult) { return startLogsResult.handleLog(message, statusType, context, date); });
        },
    };
}
function overrideInitConfigurationForBridge(initConfiguration) {
    return assign({}, initConfiguration, { clientToken: 'empty' });
}

var LOGS_STORAGE_KEY = 'logs';
function makeLogsPublicApi(startLogsImpl) {
    var customerDataTrackerManager = createCustomerDataTrackerManager();
    var globalContextManager = createContextManager(customerDataTrackerManager.getOrCreateTracker(2 /* CustomerDataType.GlobalContext */));
    var userContextManager = createContextManager(customerDataTrackerManager.getOrCreateTracker(1 /* CustomerDataType.User */));
    var trackingConsentState = createTrackingConsentState();
    function getCommonContext() {
        return buildCommonContext(globalContextManager, userContextManager);
    }
    var strategy = createPreStartStrategy(getCommonContext, trackingConsentState, function (initConfiguration, configuration) {
        if (initConfiguration.storeContextsAcrossPages) {
            storeContextManager(configuration, globalContextManager, LOGS_STORAGE_KEY, 2 /* CustomerDataType.GlobalContext */);
            storeContextManager(configuration, userContextManager, LOGS_STORAGE_KEY, 1 /* CustomerDataType.User */);
        }
        var startLogsResult = startLogsImpl(initConfiguration, configuration, getCommonContext, trackingConsentState);
        strategy = createPostStartStrategy(initConfiguration, startLogsResult);
        return startLogsResult;
    });
    var customLoggers = {};
    var mainLogger = new Logger(function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        return strategy.handleLog.apply(strategy, params);
    }, customerDataTrackerManager.createDetachedTracker());
    return makePublicApi({
        logger: mainLogger,
        init: monitor(function (initConfiguration) { return strategy.init(initConfiguration); }),
        /**
         * Set the tracking consent of the current user.
         *
         * @param {"granted" | "not-granted"} trackingConsent The user tracking consent
         *
         * Logs will be sent only if it is set to "granted". This value won't be stored by the library
         * across page loads: you will need to call this method or set the appropriate `trackingConsent`
         * field in the init() method at each page load.
         *
         * If this method is called before the init() method, the provided value will take precedence
         * over the one provided as initialization parameter.
         */
        setTrackingConsent: monitor(function (trackingConsent) {
            trackingConsentState.update(trackingConsent);
            addTelemetryUsage({ feature: 'set-tracking-consent', tracking_consent: trackingConsent });
        }),
        getGlobalContext: monitor(function () { return globalContextManager.getContext(); }),
        setGlobalContext: monitor(function (context) { return globalContextManager.setContext(context); }),
        setGlobalContextProperty: monitor(function (key, value) { return globalContextManager.setContextProperty(key, value); }),
        removeGlobalContextProperty: monitor(function (key) { return globalContextManager.removeContextProperty(key); }),
        clearGlobalContext: monitor(function () { return globalContextManager.clearContext(); }),
        createLogger: monitor(function (name, conf) {
            if (conf === void 0) { conf = {}; }
            customLoggers[name] = new Logger(function () {
                var params = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    params[_i] = arguments[_i];
                }
                return strategy.handleLog.apply(strategy, params);
            }, customerDataTrackerManager.createDetachedTracker(), sanitize(name), conf.handler, conf.level, sanitize(conf.context));
            return customLoggers[name];
        }),
        getLogger: monitor(function (name) { return customLoggers[name]; }),
        getInitConfiguration: monitor(function () { return deepClone(strategy.initConfiguration); }),
        getInternalContext: monitor(function (startTime) { return strategy.getInternalContext(startTime); }),
        setUser: monitor(function (newUser) {
            if (checkUser(newUser)) {
                userContextManager.setContext(sanitizeUser(newUser));
            }
        }),
        getUser: monitor(function () { return userContextManager.getContext(); }),
        setUserProperty: monitor(function (key, property) {
            var _a;
            var sanitizedProperty = sanitizeUser((_a = {}, _a[key] = property, _a))[key];
            userContextManager.setContextProperty(key, sanitizedProperty);
        }),
        removeUserProperty: monitor(function (key) { return userContextManager.removeContextProperty(key); }),
        clearUser: monitor(function () { return userContextManager.clearContext(); }),
    });
}
function createPostStartStrategy(initConfiguration, startLogsResult) {
    return assign({
        init: function (initConfiguration) {
            displayAlreadyInitializedError('DD_LOGS', initConfiguration);
        },
        initConfiguration: initConfiguration,
    }, startLogsResult);
}

var LOGS_SESSION_KEY = 'logs';
function startLogsSessionManager(configuration, trackingConsentState) {
    var sessionManager = startSessionManager(configuration, LOGS_SESSION_KEY, function (rawTrackingType) { return computeSessionState(configuration, rawTrackingType); }, trackingConsentState);
    return {
        findTrackedSession: function (startTime, options) {
            if (options === void 0) { options = { returnInactive: false }; }
            var session = sessionManager.findSession(startTime, options);
            return session && session.trackingType === "1" /* LoggerTrackingType.TRACKED */
                ? {
                    id: session.id,
                }
                : undefined;
        },
        expireObservable: sessionManager.expireObservable,
    };
}
function startLogsSessionManagerStub(configuration) {
    var isTracked = computeTrackingType(configuration) === "1" /* LoggerTrackingType.TRACKED */;
    var session = isTracked ? {} : undefined;
    return {
        findTrackedSession: function () { return session; },
        expireObservable: new Observable(),
    };
}
function computeTrackingType(configuration) {
    if (!performDraw(configuration.sessionSampleRate)) {
        return "0" /* LoggerTrackingType.NOT_TRACKED */;
    }
    return "1" /* LoggerTrackingType.TRACKED */;
}
function computeSessionState(configuration, rawSessionType) {
    var trackingType = hasValidLoggerSession(rawSessionType) ? rawSessionType : computeTrackingType(configuration);
    return {
        trackingType: trackingType,
        isTracked: trackingType === "1" /* LoggerTrackingType.TRACKED */,
    };
}
function hasValidLoggerSession(trackingType) {
    return trackingType === "0" /* LoggerTrackingType.NOT_TRACKED */ || trackingType === "1" /* LoggerTrackingType.TRACKED */;
}

var logsSentBeforeRumInjectionTelemetryAdded = false;
function getRUMInternalContext(startTime) {
    var browserWindow = window;
    if (willSyntheticsInjectRum()) {
        var context = getInternalContextFromRumGlobal(browserWindow.DD_RUM_SYNTHETICS);
        if (!context && !logsSentBeforeRumInjectionTelemetryAdded) {
            logsSentBeforeRumInjectionTelemetryAdded = true;
            addTelemetryDebug('Logs sent before RUM is injected by the synthetics worker', {
                testId: getSyntheticsTestId(),
                resultId: getSyntheticsResultId(),
            });
        }
        return context;
    }
    return getInternalContextFromRumGlobal(browserWindow.DD_RUM);
    function getInternalContextFromRumGlobal(rumGlobal) {
        if (rumGlobal && rumGlobal.getInternalContext) {
            return rumGlobal.getInternalContext(startTime);
        }
    }
}

function startLogsAssembly(sessionManager, configuration, lifeCycle, getCommonContext, reportError) {
    var statusWithCustom = STATUSES.concat(['custom']);
    var logRateLimiters = {};
    statusWithCustom.forEach(function (status) {
        logRateLimiters[status] = createEventRateLimiter(status, configuration.eventRateLimiterThreshold, reportError);
    });
    lifeCycle.subscribe(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, function (_a) {
        var _b, _c;
        var rawLogsEvent = _a.rawLogsEvent, _d = _a.messageContext, messageContext = _d === void 0 ? undefined : _d, _e = _a.savedCommonContext, savedCommonContext = _e === void 0 ? undefined : _e, domainContext = _a.domainContext;
        var startTime = getRelativeTime(rawLogsEvent.date);
        var session = sessionManager.findTrackedSession(startTime);
        if (!session &&
            (!configuration.sendLogsAfterSessionExpiration ||
                !sessionManager.findTrackedSession(startTime, { returnInactive: true }))) {
            return;
        }
        var commonContext = savedCommonContext || getCommonContext();
        var log = combine({
            service: configuration.service,
            session_id: session === null || session === void 0 ? void 0 : session.id,
            // Insert user first to allow overrides from global context
            usr: !isEmptyObject(commonContext.user) ? commonContext.user : undefined,
            view: commonContext.view,
        }, commonContext.context, getRUMInternalContext(startTime), rawLogsEvent, messageContext);
        if (((_b = configuration.beforeSend) === null || _b === void 0 ? void 0 : _b.call(configuration, log, domainContext)) === false ||
            (log.origin !== ErrorSource.AGENT &&
                ((_c = logRateLimiters[log.status]) !== null && _c !== void 0 ? _c : logRateLimiters['custom']).isLimitReached())) {
            return;
        }
        lifeCycle.notify(1 /* LifeCycleEventType.LOG_COLLECTED */, log);
    });
}

var _a$2;
var LogStatusForApi = (_a$2 = {},
    _a$2[ConsoleApiName.log] = StatusType.info,
    _a$2[ConsoleApiName.debug] = StatusType.debug,
    _a$2[ConsoleApiName.info] = StatusType.info,
    _a$2[ConsoleApiName.warn] = StatusType.warn,
    _a$2[ConsoleApiName.error] = StatusType.error,
    _a$2);
function startConsoleCollection(configuration, lifeCycle) {
    var consoleSubscription = initConsoleObservable(configuration.forwardConsoleLogs).subscribe(function (log) {
        lifeCycle.notify(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, {
            rawLogsEvent: {
                date: timeStampNow(),
                message: log.message,
                origin: ErrorSource.CONSOLE,
                error: log.api === ConsoleApiName.error
                    ? {
                        stack: log.stack,
                        fingerprint: log.fingerprint,
                        causes: log.causes,
                    }
                    : undefined,
                status: LogStatusForApi[log.api],
            },
        });
    });
    return {
        stop: function () {
            consoleSubscription.unsubscribe();
        },
    };
}

var _a$1;
var LogStatusForReport = (_a$1 = {},
    _a$1[RawReportType.cspViolation] = StatusType.error,
    _a$1[RawReportType.intervention] = StatusType.error,
    _a$1[RawReportType.deprecation] = StatusType.warn,
    _a$1);
function startReportCollection(configuration, lifeCycle) {
    var reportSubscription = initReportObservable(configuration, configuration.forwardReports).subscribe(function (report) {
        var message = report.message;
        var status = LogStatusForReport[report.type];
        var error;
        if (status === StatusType.error) {
            error = {
                kind: report.subtype,
                stack: report.stack,
            };
        }
        else if (report.stack) {
            message += " Found in ".concat(getFileFromStackTraceString(report.stack));
        }
        lifeCycle.notify(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, {
            rawLogsEvent: {
                date: timeStampNow(),
                message: message,
                origin: ErrorSource.REPORT,
                error: error,
                status: status,
            },
        });
    });
    return {
        stop: function () {
            reportSubscription.unsubscribe();
        },
    };
}

function startNetworkErrorCollection(configuration, lifeCycle) {
    if (!configuration.forwardErrorsToLogs) {
        return { stop: noop };
    }
    var xhrSubscription = initXhrObservable(configuration).subscribe(function (context) {
        if (context.state === 'complete') {
            handleResponse("xhr" /* RequestType.XHR */, context);
        }
    });
    var fetchSubscription = initFetchObservable().subscribe(function (context) {
        if (context.state === 'resolve') {
            handleResponse("fetch" /* RequestType.FETCH */, context);
        }
    });
    function handleResponse(type, request) {
        if (!configuration.isIntakeUrl(request.url) && (isRejected(request) || isServerError(request.status))) {
            if ('xhr' in request) {
                computeXhrResponseData(request.xhr, configuration, onResponseDataAvailable);
            }
            else if (request.response) {
                computeFetchResponseText(request.response, configuration, onResponseDataAvailable);
            }
            else if (request.error) {
                computeFetchErrorText(request.error, configuration, onResponseDataAvailable);
            }
        }
        function onResponseDataAvailable(responseData) {
            var domainContext = {
                isAborted: request.isAborted,
            };
            lifeCycle.notify(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, {
                rawLogsEvent: {
                    message: "".concat(format(type), " error ").concat(request.method, " ").concat(request.url),
                    date: request.startClocks.timeStamp,
                    error: {
                        stack: responseData || 'Failed to load',
                    },
                    http: {
                        method: request.method, // Cast resource method because of case mismatch cf issue RUMF-1152
                        status_code: request.status,
                        url: request.url,
                    },
                    status: StatusType.error,
                    origin: ErrorSource.NETWORK,
                },
                domainContext: domainContext,
            });
        }
    }
    return {
        stop: function () {
            xhrSubscription.unsubscribe();
            fetchSubscription.unsubscribe();
        },
    };
}
// TODO: ideally, computeXhrResponseData should always call the callback with a string instead of
// `unknown`. But to keep backward compatibility, in the case of XHR with a `responseType` different
// than "text", the response data should be whatever `xhr.response` is. This is a bit confusing as
// Logs event 'stack' is expected to be a string. This should be changed in a future major version
// as it could be a breaking change.
function computeXhrResponseData(xhr, configuration, callback) {
    if (typeof xhr.response === 'string') {
        callback(truncateResponseText(xhr.response, configuration));
    }
    else {
        callback(xhr.response);
    }
}
function computeFetchErrorText(error, configuration, callback) {
    callback(truncateResponseText(toStackTraceString(computeStackTrace(error)), configuration));
}
function computeFetchResponseText(response, configuration, callback) {
    var clonedResponse = tryToClone(response);
    if (!clonedResponse || !clonedResponse.body) {
        // if the clone failed or if the body is null, let's not try to read it.
        callback();
    }
    else if (!window.TextDecoder) {
        // If the browser doesn't support TextDecoder, let's read the whole response then truncate it.
        //
        // This should only be the case on early versions of Edge (before they migrated to Chromium).
        // Even if it could be possible to implement a workaround for the missing TextDecoder API (using
        // a Blob and FileReader), we found another issue preventing us from reading only the first
        // bytes from the response: contrary to other browsers, when reading from the cloned response,
        // if the original response gets canceled, the cloned response is also canceled and we can't
        // know about it.  In the following illustration, the promise returned by `reader.read()` may
        // never be fulfilled:
        //
        // fetch('/').then((response) => {
        //   const reader = response.clone().body.getReader()
        //   readMore()
        //   function readMore() {
        //     reader.read().then(
        //       (result) => {
        //         if (result.done) {
        //           console.log('done')
        //         } else {
        //           readMore()
        //         }
        //       },
        //       () => console.log('error')
        //     )
        //   }
        //   response.body.getReader().cancel()
        // })
        clonedResponse.text().then(monitor(function (text) { return callback(truncateResponseText(text, configuration)); }), monitor(function (error) { return callback("Unable to retrieve response: ".concat(error)); }));
    }
    else {
        truncateResponseStream(clonedResponse.body, configuration.requestErrorResponseLengthLimit, function (error, responseText) {
            if (error) {
                callback("Unable to retrieve response: ".concat(error));
            }
            else {
                callback(responseText);
            }
        });
    }
}
function isRejected(request) {
    return request.status === 0 && request.responseType !== 'opaque';
}
function truncateResponseText(responseText, configuration) {
    if (responseText.length > configuration.requestErrorResponseLengthLimit) {
        return "".concat(responseText.substring(0, configuration.requestErrorResponseLengthLimit), "...");
    }
    return responseText;
}
function format(type) {
    if ("xhr" /* RequestType.XHR */ === type) {
        return 'XHR';
    }
    return 'Fetch';
}
function truncateResponseStream(stream, bytesLimit, callback) {
    readBytesFromStream(stream, function (error, bytes, limitExceeded) {
        if (error) {
            callback(error);
        }
        else {
            var responseText = new TextDecoder().decode(bytes);
            if (limitExceeded) {
                responseText += '...';
            }
            callback(undefined, responseText);
        }
    }, {
        bytesLimit: bytesLimit,
        collectStreamBody: true,
    });
}

function startRuntimeErrorCollection(configuration, lifeCycle) {
    if (!configuration.forwardErrorsToLogs) {
        return { stop: noop };
    }
    var rawErrorObservable = new Observable();
    var stopRuntimeErrorTracking = trackRuntimeError(rawErrorObservable).stop;
    var rawErrorSubscription = rawErrorObservable.subscribe(function (rawError) {
        lifeCycle.notify(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, {
            rawLogsEvent: {
                message: rawError.message,
                date: rawError.startClocks.timeStamp,
                error: {
                    kind: rawError.type,
                    stack: rawError.stack,
                    causes: rawError.causes,
                },
                origin: ErrorSource.SOURCE,
                status: StatusType.error,
            },
        });
    });
    return {
        stop: function () {
            stopRuntimeErrorTracking();
            rawErrorSubscription.unsubscribe();
        },
    };
}

var LifeCycle = (AbstractLifeCycle);

var _a;
var STATUS_PRIORITIES = (_a = {},
    _a[StatusType.debug] = 0,
    _a[StatusType.info] = 1,
    _a[StatusType.warn] = 2,
    _a[StatusType.error] = 3,
    _a);
function startLoggerCollection(lifeCycle) {
    function handleLog(logsMessage, logger, savedCommonContext, savedDate) {
        var messageContext = combine(logger.getContext(), logsMessage.context);
        if (isAuthorized(logsMessage.status, HandlerType.console, logger)) {
            displayInConsole(logsMessage, messageContext);
        }
        if (isAuthorized(logsMessage.status, HandlerType.http, logger)) {
            lifeCycle.notify(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, {
                rawLogsEvent: {
                    date: savedDate || timeStampNow(),
                    message: logsMessage.message,
                    status: logsMessage.status,
                    origin: ErrorSource.LOGGER,
                },
                messageContext: messageContext,
                savedCommonContext: savedCommonContext,
            });
        }
    }
    return {
        handleLog: handleLog,
    };
}
function isAuthorized(status, handlerType, logger) {
    var loggerHandler = logger.getHandler();
    var sanitizedHandlerType = Array.isArray(loggerHandler) ? loggerHandler : [loggerHandler];
    return (STATUS_PRIORITIES[status] >= STATUS_PRIORITIES[logger.getLevel()] && includes(sanitizedHandlerType, handlerType));
}
function displayInConsole(logsMessage, messageContext) {
    originalConsoleMethods[logsMessage.status].call(globalConsole, logsMessage.message, messageContext);
}

function startLogsBatch(configuration, lifeCycle, reportError, pageExitObservable, session) {
    var batch = startBatchWithReplica(configuration, {
        endpoint: configuration.logsEndpointBuilder,
        encoder: createIdentityEncoder(),
    }, configuration.replica && {
        endpoint: configuration.replica.logsEndpointBuilder,
        encoder: createIdentityEncoder(),
    }, reportError, pageExitObservable, session.expireObservable);
    lifeCycle.subscribe(1 /* LifeCycleEventType.LOG_COLLECTED */, function (serverLogsEvent) {
        batch.add(serverLogsEvent);
    });
    return batch;
}

function startLogsBridge(lifeCycle) {
    var bridge = getEventBridge();
    lifeCycle.subscribe(1 /* LifeCycleEventType.LOG_COLLECTED */, function (serverLogsEvent) {
        bridge.send('log', serverLogsEvent);
    });
}

function startInternalContext(sessionManager) {
    return {
        get: function (startTime) {
            var trackedSession = sessionManager.findTrackedSession(startTime);
            if (trackedSession) {
                return {
                    session_id: trackedSession.id,
                };
            }
        },
    };
}

function startReportError(lifeCycle) {
    return function (error) {
        lifeCycle.notify(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, {
            rawLogsEvent: {
                message: error.message,
                date: error.startClocks.timeStamp,
                origin: ErrorSource.AGENT,
                status: StatusType.error,
            },
        });
        addTelemetryDebug('Error reported to customer', { 'error.message': error.message });
    };
}

function startLogsTelemetry(initConfiguration, configuration, reportError, pageExitObservable, session) {
    var telemetry = startTelemetry("browser-logs-sdk" /* TelemetryService.LOGS */, configuration);
    telemetry.setContextProvider(function () {
        var _a, _b, _c, _d, _e, _f;
        return ({
            application: {
                id: (_a = getRUMInternalContext()) === null || _a === void 0 ? void 0 : _a.application_id,
            },
            session: {
                id: (_b = session.findTrackedSession()) === null || _b === void 0 ? void 0 : _b.id,
            },
            view: {
                id: (_d = (_c = getRUMInternalContext()) === null || _c === void 0 ? void 0 : _c.view) === null || _d === void 0 ? void 0 : _d.id,
            },
            action: {
                id: (_f = (_e = getRUMInternalContext()) === null || _e === void 0 ? void 0 : _e.user_action) === null || _f === void 0 ? void 0 : _f.id,
            },
        });
    });
    var cleanupTasks = [];
    if (canUseEventBridge()) {
        var bridge_1 = getEventBridge();
        var telemetrySubscription_1 = telemetry.observable.subscribe(function (event) { return bridge_1.send('internal_telemetry', event); });
        cleanupTasks.push(function () { return telemetrySubscription_1.unsubscribe(); });
    }
    else {
        var telemetryBatch_1 = startBatchWithReplica(configuration, {
            endpoint: configuration.rumEndpointBuilder,
            encoder: createIdentityEncoder(),
        }, configuration.replica && {
            endpoint: configuration.replica.rumEndpointBuilder,
            encoder: createIdentityEncoder(),
        }, reportError, pageExitObservable, session.expireObservable);
        cleanupTasks.push(function () { return telemetryBatch_1.stop(); });
        var telemetrySubscription_2 = telemetry.observable.subscribe(function (event) {
            return telemetryBatch_1.add(event, isTelemetryReplicationAllowed(configuration));
        });
        cleanupTasks.push(function () { return telemetrySubscription_2.unsubscribe(); });
    }
    drainPreStartTelemetry();
    addTelemetryConfiguration(serializeLogsConfiguration(initConfiguration));
    return {
        telemetry: telemetry,
        stop: function () {
            cleanupTasks.forEach(function (task) { return task(); });
        },
    };
}

function startLogs(initConfiguration, configuration, getCommonContext, 
// `startLogs` and its subcomponents assume tracking consent is granted initially and starts
// collecting logs unconditionally. As such, `startLogs` should be called with a
// `trackingConsentState` set to "granted".
trackingConsentState) {
    var lifeCycle = new LifeCycle();
    var cleanupTasks = [];
    lifeCycle.subscribe(1 /* LifeCycleEventType.LOG_COLLECTED */, function (log) { return sendToExtension('logs', log); });
    var reportError = startReportError(lifeCycle);
    var pageExitObservable = createPageExitObservable(configuration);
    var session = configuration.sessionStoreStrategyType && !canUseEventBridge() && !willSyntheticsInjectRum()
        ? startLogsSessionManager(configuration, trackingConsentState)
        : startLogsSessionManagerStub(configuration);
    var stopLogsTelemetry = startLogsTelemetry(initConfiguration, configuration, reportError, pageExitObservable, session).stop;
    cleanupTasks.push(function () { return stopLogsTelemetry(); });
    startNetworkErrorCollection(configuration, lifeCycle);
    startRuntimeErrorCollection(configuration, lifeCycle);
    startConsoleCollection(configuration, lifeCycle);
    startReportCollection(configuration, lifeCycle);
    var handleLog = startLoggerCollection(lifeCycle).handleLog;
    startLogsAssembly(session, configuration, lifeCycle, getCommonContext, reportError);
    if (!canUseEventBridge()) {
        var stopLogsBatch_1 = startLogsBatch(configuration, lifeCycle, reportError, pageExitObservable, session).stop;
        cleanupTasks.push(function () { return stopLogsBatch_1(); });
    }
    else {
        startLogsBridge(lifeCycle);
    }
    var internalContext = startInternalContext(session);
    return {
        handleLog: handleLog,
        getInternalContext: internalContext.get,
        stop: function () {
            cleanupTasks.forEach(function (task) { return task(); });
        },
    };
}

var datadogLogs = makeLogsPublicApi(startLogs);
defineGlobal(getGlobalObject(), 'DD_LOGS', datadogLogs);

const createClientLogger = ()=>{
    let isInitialized = false;
    return {
        initializeOnce (context) {
            if (isInitialized) return;
            initializeDatadog(context);
            isInitialized = true;
        },
        getLogger ({ name, ...restContext }) {
            const logger = datadogLogs.getLogger(name) ?? datadogLogs.createLogger(name, {
                context: restContext
            });
            return {
                info (message, context) {
                    // @todo Future opportunity depending on costing evolution over time: to reduce cost in production, prevent logging in Datadog non-critical logs (info-level ones) in live and enable them only for dev and preview (for debugging purposes).
                    logger.info(message, context);
                },
                warn (message, context) {
                    logger.warn(message, context);
                },
                error (input, context) {
                    if (input instanceof Error) {
                        logger.error(input.message, context, input);
                        return;
                    }
                    logger.error(input, context);
                }
            };
        }
    };
};
/**
 * Initialize the Datadog client with the provided configuration.
 */ const initializeDatadog = (context)=>{
    if (context.name === SHELL_ID) {
        datadogLogs.init({
            clientToken: "pub0253ac23765c70828c70cebd7635e17a",
            site: "datadoghq.eu",
            service: "ufrn.shell",
            env: context.environment,
            // So far we decided to hardcode the sourcemaps version to 2
            // We will see in the future if we need this version to be dynamic
            // If this change, please update the circleci configuration file
            // Ticket related: https://avivgroup.atlassian.net/browse/TOS-577
            version: "2",
            sessionSampleRate: context.environment === "live" ? 2 : 100
        });
        const { name, version, ...globalContext } = context;
        datadogLogs.setGlobalContext({
            ...globalContext,
            runtime: "client"
        });
    }
};

const createServerLogger = ()=>{
    return {
        initializeOnce () {
        // Server implementation has nothing to initialize.
        },
        getLogger ({ name, ...restBaseContext }) {
            const log = (methodName, message, logContext, error)=>{
                const input = [
                    `${name}: ${message} - ${JSON.stringify({
                        ...restBaseContext,
                        ...logContext,
                        name,
                        level: methodName,
                        runtime: "server"
                    }, (_, value)=>{
                        if (!(value instanceof Error)) {
                            return value;
                        }
                        // By design, `JSON.stringify` stringifies only enumerable properties.
                        // However, Error object doesn't have any enumerable properties leading to empty object print.
                        // By managing a custom replacer case for an instance of Error, we can force the inclusion of non-enumerable values
                        // thanks to `Object.getOwnPropertyNames` API that iterates over enumerable and non-enumerable properties
                        return Object.getOwnPropertyNames(value).reduce((output, key)=>{
                            output[key] = value[key];
                            return output;
                        }, {});
                    })}`,
                    error
                ].filter(Boolean);
                console[methodName](...input);
            };
            return {
                info: (message, context)=>log("info", message, context),
                warn: (message, context)=>log("warn", message, context),
                error: (input, context)=>{
                    if (input instanceof Error) {
                        log("error", input.message, context, input);
                        return;
                    }
                    log("error", input, context);
                }
            };
        }
    };
};

const logger = IS_SERVER_ENVIRONMENT ? createServerLogger() : createClientLogger();
/**
 * Create a new logger.
 * If the logger already exists, it will return the existing one.
 * @param context The base context used for initialization
 * @returns A new logger linked to the id parameter
 */ const createLogger = (context)=>{
    logger.initializeOnce(context);
    return logger.getLogger(context);
};

export { createLogger };
